import { theme } from '@minted/minted-components';
import axios from 'axios';
import config from 'config';

import { getReferrer } from '../../browser/utils';
import { hashPath } from '../../track/utils';

const defaultSettings = {
  endpoint: null,
};

const defaultPayload = {
  feature: 'wedding_site_customize',
};

class Track {
  constructor() {
    this.reset();
    this.api = null;
    this.configure({
      endpoint: config.get('trackEndpoint'),
    });
  }

  reset() {
    this.settings = defaultSettings;
  }

  configure(settings) {
    this.settings = {
      ...this.settings,
      ...settings,
    };
    const acceptHeader = 'application/json';

    this.instance = axios.create({
      headers: {
        Accept: acceptHeader,
      },
      withCredentials: true,
    });
  }

  emit(payload) {
    const mergedPayload = {
      ...defaultPayload,
      ...payload,
    };

    if (this.settings.endpoint) {
      const visitorCookie = document.cookie.split(';')
        .map((cookie) => cookie.trim())
        .find((cookie) => cookie.startsWith('visitorId='));

      if (Boolean(visitorCookie)) {
        const visitorId = visitorCookie.replace('visitorId=', '');

        mergedPayload.visitor_id = visitorId;
      }

      const searchParams = new URLSearchParams(mergedPayload);
      const endpointUri = `${this.settings.endpoint}?${searchParams.toString()}`;

      return this.instance.get(endpointUri.toString())
        .then((response) => response);
    }
    console.info('Cannot emit track event, no endpoint configured - payload is:', mergedPayload);
  }
}

const track = new Track();

export default track;

export const trackPageLoad = (featureName, site) => {
  const width = document.documentElement.clientWidth;
  const smallViewport = theme.breakpointsList.find((breakpoint) => (breakpoint.breakpointName === 'small'));
  const isMobile = width < smallViewport.breakpointValue;
  const referrer = getReferrer();

  track.emit({
    event: 'pageview_log',
    feature: featureName,
    is_mobile: isMobile,
    referrer_domain: referrer.split('?')[0],
    referrer_hash: hashPath(referrer),
    site_id: site.id,
    site_type: site.siteType,
    url_hash: hashPath(window.location.href),
  });
};
