export const presetRegistries = {
  Amazon: {
    title: 'Amazon',
    url: 'https://www.amazon.com/wedding/',
  },
  'Bed Bath & Beyond': {
    title: 'Bed Bath & Beyond',
    url: 'https://www.bedbathandbeyond.com/store/page/Registry',
  },
  "Bloomingdale's": {
    title: "Bloomingdale's",
    url: 'https://www.bloomingdales.com/wedding-registry',
  },
  'Blueprint Registry': {
    title: 'Blueprint Registry',
    url: 'https://www.blueprintregistry.com/',
  },
  'Crate & Barrel': {
    title: 'Crate & Barrel',
    url: 'https://www.crateandbarrel.com/gift-registry/',
  },
  custom: {
    url: '',
  },
  "Macy's": {
    title: "Macy's",
    url: 'https://www.macys.com/wedding-registry',
  },
  'MyRegistry.com': {
    title: 'MyRegistry.com',
    url: 'https://www.myregistry.com',
  },
  'Pottery Barn': {
    title: 'Pottery Barn',
    url: 'https://www.potterybarn.com/registry/',
  },
  Sears: {
    title: 'Sears',
    url: 'https://www.sears.com/shc/s/GiftRegistryApp',
  },
  Target: {
    title: 'Target',
    url: 'https://www.target.com/gift-registry/',
  },
  'West Elm': {
    title: 'West Elm',
    url: 'https://secure.westelm.com/registry/find-registry.html',
  },
  'Williams-Sonoma': {
    title: 'Williams-Sonoma',
    url: 'https://williams-sonoma.com/registry/',
  },
  Zola: {
    title: 'Zola',
    url: 'https://www.zola.com/find-registry',
  },
};

export const registrySelections = (
  Object
    .keys(presetRegistries)
    .filter((key) => (
      key !== 'custom'
    ))
    .map((key) => ({
      text: key,
      value: key,
    }))
);

// Birdie has a list of different types of registry types, but all this really does is change things
// like placeholder text the user sees when filling out their cash registry form.
export const CASH_REGISTRY_TYPES = {
  BABY_SHOWER: 'Baby Shower',
  BAR_MITZVAH: 'Bar Mitzvah',
  BIRTHDAY: 'Birthday',
  BRIDAL_SHOWER: 'Bridal Shower',
  GRADUATION: 'Graduation',
  OTHER: 'Other',
  WEDDING: 'Wedding',
};
