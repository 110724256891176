import { css } from '@emotion/react';
import {
  theme,
  Divider,
  ButtonPicker,
  ButtonPickerButton,
  TextInput,
  Textarea,
  useMediaQueryState,
  FadeReveal,
  styleUtils,
  Icon,
} from '@minted/minted-components';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {
  string, number, arrayOf, shape, func,
  bool,
} from 'prop-types';
import React, { useCallback } from 'react';

import { QUESTION_WITH_ERROR_CLASSNAME } from './constants';
import {
  ATTENDING,
  NOT_ATTENDING,
  ATTENDING_STATUS_LABEL,
  AWAITING_REPLY,
} from '../../guests/constants';
import { isGuestNamed } from '../dashboard/utils';

const eventGuestStyles = {
  attendingStatusText: css`
    ${theme.typeStyles.bodyMediumStyles}
  `,
  attendingStatusWrapper: css`
    display: flex;
    justify-content: end;
    align-items: center;
    width: fit-content;
    float: right;
  `,
  buttonPickerWrapper: (hasError) => css`
    ${hasError && `
      button {
        border: 1px solid ${theme.colors.alert};
        &:hover {
          border: 1px solid ${theme.colors.alert};
        }
      }
    `}
  `,
  card: css`
    background-color: ${theme.colors.gray100};
    border: ${styleUtils.rem(1)} solid ${theme.colors.gray300};
    border-radius: ${theme.spacing.base};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x6};
    padding: ${theme.spacing.x4};
    padding-bottom: ${theme.spacing.x4};
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  `,
  dividerWrapper: css`
    margin-top: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
  `,
  errorStyle: css`
    ${theme.typeStyles.bodySmallStyles};
    color: #cb2647;
    margin-top: 0.25rem;
  `,
  fadeReveal: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x6};
  `,
  fullWidth: css`
    width: 100%;
  `,
  headerWrapper: css`
    display: 'inline-block';
  `,
  nameText: css`
    ${theme.typeStyles.titleMediumStyles};
    float: left;
  `,
  questionText: css`
    ${theme.typeStyles.titleAlternateMediumStyles};
  `,
  questionWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
  `,
  readOnlyResponseText: css`
    ${theme.typeStyles.bodySmallStyles}
  `,
  row: css`
    display: flex;
    gap: ${theme.spacing.x2};
  `,
};

const ButtonPickerWrapper = ({
  children,
  hasError,
}) => (
  <div css={eventGuestStyles.buttonPickerWrapper(hasError)}>
    {children}
    {
      hasError && (
        <div css={eventGuestStyles.errorStyle}>
          Question is required
        </div>
      )
    }
  </div>
);

const Question = ({
  answer, onChange, question, readOnly, shouldValidateErrors,
}) => {
  const hasError = question.required && isEmpty(answer) && shouldValidateErrors;
  const errorMessage = 'Question is required';

  return (
    <div
      className={
        classNames({
          [QUESTION_WITH_ERROR_CLASSNAME]: hasError,
        })
      }
      css={eventGuestStyles.questionWrapper}
    >
      <div css={eventGuestStyles.questionText}>
        {question.text}
        {!question.required && ' (Optional)'}
      </div>

      {
        readOnly
          ? (
            <div css={eventGuestStyles.readOnlyResponseText}>
              {answer || '(skipped)'}
            </div>
          )
          : (
            <>
              {
                question.questionType === 'freetext' && (
                  <Textarea
                    error={hasError && errorMessage}
                    hasErrorSpacing
                    minRows={2}
                    name={question.text}
                    onChange={(event) => onChange(event, question.id)}
                    placeholder="Your message"
                    touched
                    value={answer}
                  />
                )
              }

              {
                question.questionType === 'multiple_choice' && (
                  <ButtonPickerWrapper hasError={hasError}>
                    <ButtonPicker
                      name={question.text}
                      onChange={(event) => onChange(event, question.id)}
                      size="small"
                      value={answer}
                    >
                      {
                        question.choices.map((choice) => (
                          <ButtonPickerButton
                            key={choice}
                            value={choice}
                          >
                            {choice}
                          </ButtonPickerButton>
                        ))
                      }
                    </ButtonPicker>
                  </ButtonPickerWrapper>
                )
              }
            </>
          )
      }
    </div>
  );
};

const propTypes = {
  canEditName: func,
  guest: shape({
    firstName: string,
    id: number.isRequired,
    lastName: string,
  }).isRequired,
  questions: arrayOf(shape({
    choices: arrayOf(string),
    questionType: string.isRequired,
    required: bool.isRequired,
    text: string.isRequired,
  })),
  updatedGuestName: shape({
    firstName: string,
    lastName: string,
  }),
  updateGuestName: func,
};

const defaultProps = {
  canEditName: noop,
  updateGuestName: noop,
};

const EventGuest = ({
  canEditName,
  guest,
  guestResponse,
  questions,
  readOnly,
  updateGuestName,
  updateGuestResponses,
  updatedGuestName,
  validateErrors,
}) => {
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  const isUnnamed = !isGuestNamed(guest);
  const showQuestions = (!readOnly && !isEmpty(questions)) || (readOnly && guestResponse?.responseType === ATTENDING && !isEmpty(questions));

  const onAttendingChange = useCallback((event) => {
    event.persist();
    const newData = {
      ...guestResponse,
      responseType: event.target.value,
    };

    updateGuestResponses(guest.id, newData, true);
  }, [
    guestResponse,
    guest.id,
    updateGuestResponses,
  ]);

  const onAnswerChange = useCallback((event, questionId) => {
    event.persist();
    const newData = {
      ...guestResponse,
      answers: {
        ...guestResponse.answers,
        [questionId]: event.target.value,
      },
    };

    updateGuestResponses(guest.id, newData);
  }, [
    guestResponse,
    guest.id,
    updateGuestResponses,
  ]);

  let name = `${guest.firstName} ${guest.lastName}`;

  if (isUnnamed) {
    const updatedName = `${updatedGuestName?.firstName ?? ''} ${updatedGuestName?.lastName ?? ''}`;

    name = isEmpty(updatedName.trim()) ? 'Unnamed Guest' : updatedName;
  }

  const iconFillColor = css`
    svg > path {
      fill: ${guestResponse?.responseType === ATTENDING ? theme.colors.accent600 : theme.colors.negative600};
    }
  `;

  const shouldValidateQuestions = guestResponse?.responseType === ATTENDING && validateErrors;
  const validateAttendingStatus = guestResponse?.responseType === AWAITING_REPLY && validateErrors;
  const displayNameInputs = guestResponse?.responseType === ATTENDING && canEditName(guest.id);
  const hasFirstNameError = shouldValidateQuestions && isEmpty(updatedGuestName?.firstName?.trim()) && 'First Name is required';
  const hasLastNameError = shouldValidateQuestions && isEmpty(updatedGuestName?.lastName?.trim()) && 'Last Name is required';

  return (
    <div css={eventGuestStyles.card}>
      <div css={eventGuestStyles.headerWrapper}>
        <div css={eventGuestStyles.nameText}>
          {name}
        </div>

        {
          readOnly && (
            <div
              css={
                css`
                  ${eventGuestStyles.attendingStatusWrapper};
                  ${iconFillColor}
                `
              }
            >
              <Icon
                size="small"
                type={guestResponse?.responseType === ATTENDING ? 'check' : 'close'}
              />

              <div css={eventGuestStyles.attendingStatusText}>
                {ATTENDING_STATUS_LABEL[guestResponse?.responseType]}
              </div>
            </div>

          )
        }
      </div>

      {
        showQuestions && (
          <div css={eventGuestStyles.dividerWrapper}>
            <Divider
              spacing="none"
              type="light"
            />
          </div>
        )
      }

      {
        !readOnly && (
          <div
            className={
              classNames({
                [QUESTION_WITH_ERROR_CLASSNAME]: validateAttendingStatus,
              })
            }
            css={eventGuestStyles.questionWrapper}
          >
            <div css={eventGuestStyles.questionText}>
              Will you be attending this event?
            </div>

            <ButtonPickerWrapper hasError={validateAttendingStatus}>
              <ButtonPicker
                layout="horizontalLayout"
                name="attending"
                onChange={(event) => onAttendingChange(event)}
                size="small"
                value={guestResponse.responseType}
              >
                <ButtonPickerButton value={ATTENDING}>
                  Attending
                </ButtonPickerButton>

                <ButtonPickerButton value={NOT_ATTENDING}>
                  Not Attending
                </ButtonPickerButton>
              </ButtonPicker>
            </ButtonPickerWrapper>
          </div>
        )
      }

      {
        displayNameInputs && (
          <div
            className={
              classNames({
                [QUESTION_WITH_ERROR_CLASSNAME]: hasFirstNameError || hasLastNameError,
              })
            }
            css={eventGuestStyles.questionWrapper}
          >
            <div css={eventGuestStyles.questionText}>
              Name of Guest
            </div>

            <div css={isMobile ? eventGuestStyles.column : eventGuestStyles.row}>
              <div css={eventGuestStyles.fullWidth}>
                <TextInput
                  error={hasFirstNameError}
                  label="First Name"
                  name={`guestFirstName${guest.id}`}
                  onChange={
                    (event) => {
                      updateGuestName(guest.id, 'firstName', event.target.value);
                    }
                  }
                  touched
                  value={updatedGuestName?.firstName || ''}
                />
              </div>

              <div css={eventGuestStyles.fullWidth}>
                <TextInput
                  error={hasLastNameError}
                  label="Last Name"
                  name={`guestLastName${guest.id}`}
                  onChange={
                    (event) => {
                      updateGuestName(guest.id, 'lastName', event.target.value);
                    }
                  }
                  touched
                  value={updatedGuestName?.lastName || ''}
                />
              </div>
            </div>
          </div>
        )
      }

      {
        showQuestions && (
          <FadeReveal
            css={eventGuestStyles.fadeReveal}
            in={
              guestResponse.responseType && guestResponse.responseType === ATTENDING && !isEmpty(questions)
            }
            unmountOnExit
          >
            {
              questions.map((question) => (
                <Question
                  answer={guestResponse.answers[question.id]}
                  key={`${guest.id}${question.id}`}
                  onChange={onAnswerChange}
                  question={question}
                  readOnly={readOnly}
                  shouldValidateErrors={shouldValidateQuestions}
                />
              ))
            }
          </FadeReveal>
        )
      }
    </div>
  );
};

EventGuest.propTypes = propTypes;
EventGuest.defaultProps = defaultProps;

export default EventGuest;
