import { css } from '@emotion/react';
import {
  theme,
  Divider,
} from '@minted/minted-components';
import {
  arrayOf, shape, objectOf, string, number, bool,
} from 'prop-types';
import React from 'react';

import EventDetails from './EventDetails';
import EventGuest from './EventGuest';
import OnlineInvitationSignup from './OnlineInvitationSignup';
import { SITE_TYPES } from '../../core/constants';
import { eventPropTypes } from '../../global/propTypes';

const eventsGuestsSummaryStyles = {
  dividerWrapper: css`
    margin-top: ${theme.spacing.x5};
    margin-bottom: 0;
  `,
  eventGuestWrapper: css`
    background-color: ${theme.colors.gray200};
    display: grid;
    gap: ${theme.spacing.x5};
    margin-bottom: ${theme.spacing.x4};
    padding: ${theme.spacing.x4};
    ${theme.media.lessThan('medium')`
      padding: ${theme.spacing.x5};
    `};
  `,
  headerWrapper: css`
    text-align: center;
    padding-top: ${theme.spacing.x12};
    padding-bottom: ${theme.spacing.x5};
    margin-left: ${theme.spacing.x5};
    margin-right: ${theme.spacing.x5};
    ${theme.media.lessThan('medium')`
      margin-left: ${theme.spacing.x4};
      margin-right: ${theme.spacing.x4};
    `};
  `,
  headingText: css`
    ${theme.typeStyles.titleExtraLargeStyles};
  `,
  subHeadingText: css`
    ${theme.typeStyles.bodySmallStyles};

    margin-bottom: ${theme.spacing.x3};
  `,
};

const EventsGuestsSummary = ({
  contact,
  events,
  guests,
  guestsResponses,
  isMobile,
  siteType,
  updatedGuestNames,
}) => (
  <div data-cy="eventsGuestsSummary">
    <div css={eventsGuestsSummaryStyles.headerWrapper}>
      <div css={eventsGuestsSummaryStyles.subHeadingText}>
        Thank you for responding!
      </div>

      <div
        css={eventsGuestsSummaryStyles.headingText}
        data-cy="eventsGuestsSummaryHeadingText"
      >
        {`Event Summary for ${contact.name}`}
      </div>

      <div css={eventsGuestsSummaryStyles.dividerWrapper}>
        <Divider
          spacing="none"
          type="light"
        />
      </div>
    </div>

    {
      events.map((event, index) => (
        <div key={`eventWrapper${event.id}`}>
          <EventDetails
            event={event}
            index={index}
            isMobile={isMobile}
            key={`summaryEvent${event.id}`}
            readOnly
            totalEvents={events.length}
          />

          <div css={eventsGuestsSummaryStyles.eventGuestWrapper}>
            {
              guests.filter((guest) => event.guests.includes(guest.id)).map((guest) => (
                <EventGuest
                  guest={guest}
                  guestResponse={guestsResponses[event.id][guest.id] ?? {}}
                  key={`summaryGuest${guest.id}`}
                  questions={event.questions}
                  readOnly
                  updatedGuestName={updatedGuestNames[guest.id] ?? {}}
                />
              ))
            }
          </div>
        </div>
      ))
    }
    {
      siteType === SITE_TYPES.ONLINE_INVITATION && (
        <OnlineInvitationSignup />
      )
    }
  </div>
);

EventsGuestsSummary.propTypes = {
  contact: shape({
    id: string,
    name: string,
  }).isRequired,
  events: arrayOf(eventPropTypes).isRequired,
  guests: arrayOf(shape({
    firstName: string,
    id: number.isRequired,
    lastName: string,
  })).isRequired,
  guestsResponses: shape({}).isRequired,
  isMobile: bool.isRequired,
  updatedGuestNames: objectOf(
    shape({
      firstName: string,
      lastName: string,
    })
  ),
};

export default EventsGuestsSummary;
