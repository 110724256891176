import React from 'react';

import { PAGE_TYPE } from './constants';

export const buildRouteArgs = (page) => ({
  name: page.data.isDefault ? `pages.${page.data.slug}` : 'pages.custom',
  params: (
    page.data.isDefault
      ? {}
      : {
        id: `${page.data.id}`,
        slug: page.data.slug,
      }
  ),
});

// Takes array of react children and interleaves a divider
export const joinChildren = (children, divider) => {
  const length = children.length;

  return children.reduce((acc, child, index) => {
    acc.push(child);
    if (index < length - 1) {
      acc.push(<div key={`separator${index}`}>
        {divider}
      </div>);
    }

    return acc;
  }, []);
};

export const getPageType = (defaultName) => {
  switch (defaultName) {
    case 'Events':
      return PAGE_TYPE.EVENTS;
    case 'Gift Registry':
      return PAGE_TYPE.GIFT_REGISTRY;
    case 'Home':
      return PAGE_TYPE.HOME;
    case 'Gallery':
      return PAGE_TYPE.GALLERY;
    case 'RSVP':
      return PAGE_TYPE.RSVP;
    case 'Wedding Party':
      return PAGE_TYPE.PARTY;
    case 'Guest Book':
      return PAGE_TYPE.GUEST_BOOK;
  }

  return PAGE_TYPE.OTHER;
};
