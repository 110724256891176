import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

export default (
  dekuraData,
) => {
  const backgroundProps = [];

  const containers = [
    'MastheadContainer',
    'IntroContainer',
    'PlacecardContainer',
  ];

  const design = {
    DesignProvider: {
      rules: get(dekuraData, 'typography.required.Text') || {},
      scopes: {},
    },
  };

  design.DesignProvider.rules.lineHeight = design.DesignProvider.rules.lineHeightFactor;

  (dekuraData.colorways || []).map((colorway) => {
    const rules = pick(get(colorway, 'required.colors'), [
      'fontColor',
      'linkColor',
      ...backgroundProps,
    ]);

    design.DesignProvider.scopes[`Colorway${colorway.colorway}`] = {
      rules: omitBy(rules, isNil),
    };
  });

  [
    'LargeHeadline',
    'MediumHeadline',
    'SmallHeadline',
    'TinyHeadline',
    'Button',
  ].forEach((key) => {
    design[key] = {
      rules: get(dekuraData, `typography.required.${key}`) || {},
      scopes: {},
    };
    design[key].rules.lineHeight = design[key].rules.lineHeightFactor;

    (dekuraData.colorways || []).map((colorway) => {
      const colorKey = key === 'Button' ? 'buttonColor' : 'fontColor';
      const value = get(colorway, `required.colors.${key}`);
      const rules = (
        value
          ? {
            [colorKey]: value,
          }
          : {}
      );

      design[key].scopes[`Colorway${colorway.colorway}`] = {
        rules: omitBy(rules, isNil),
      };
    });

    if (key === 'Button') {
      design[key].rules = {
        ...design[key].rules,
        ...(get(dekuraData, 'ui.required.Button') || {}),
      };
    }
  });

  [
    'IntroMediumHeadline',
    'IntroText',
  ].forEach((key) => {
    const rules = get(dekuraData, `typography.header.${key}`) || {};

    rules.lineHeight = rules.lineHeightFactor;

    if (!design['IntroContainer']) {
      design['IntroContainer'] = {
        rules: {},
        scopes: {},
      };
    }

    if (key === 'IntroMediumHeadline') {
      design['MediumHeadline'].scopes['IntroContainer'] = {
        rules,
        scopes: {},
      };
    } else {
      design['IntroContainer'].rules = {
        ...design['IntroContainer'].rules || {},
        ...rules,
      };
    }

    (dekuraData.colorways || []).map((colorway) => {
      const value = get(colorway, 'header.IntroContainer');

      if (value && key === 'IntroMediumHeadline') {
        design['MediumHeadline'].scopes['IntroContainer'].scopes[`Colorway${colorway.colorway}`] = {
          rules: {
            fontColor: value.MediumHeadline,
          },
        };
      } else if (value) {
        if (!design['IntroContainer'].scopes) {
          design['IntroContainer'].scopes = {};
        }
        const containerScopeData = design['IntroContainer'].scopes[`Colorway${colorway.colorway}`] || {
          rules: {},
        };

        design['IntroContainer'].scopes[`Colorway${colorway.colorway}`] = {
          ...containerScopeData,
          rules: {
            ...containerScopeData.rules,
            fontColor: value.fontColor,
            linkColor: value.linkColor,
          },
        };
      }
    });
  });

  [
    'CoupleHorizontalNames',
    'CoupleVerticalNames',
  ].forEach((key) => {
    const rules = get(dekuraData, `typography.header.${key}`);

    if (rules && rules.lineHeightFactor) {
      rules.lineHeight = rules.lineHeightFactor;
    }

    design[key] = {
      rules,
    };
    design['LargeHeadline'].scopes[key] = {
      rules: rules || {},
      scopes: {},
    };
    (dekuraData.colorways || []).map((colorway) => {
      const value = get(colorway, `required.header.${key}`);

      if (value) {
        design['LargeHeadline'].scopes[key].scopes[`Colorway${colorway.colorway}`] = {
          rules: {
            fontColor: value,
          },
        };
      }

      containers.forEach((container) => {
        const value = get(colorway, `header.${container}.${key}`);

        if (value) {
          const containerData = design['LargeHeadline'].scopes[key].scopes[container] || {};
          const containerScopes = containerData.scopes || {};
          const containerColorwayScope = containerScopes[`Colorway${colorway.colorway}`] || {
            rules: {},
          };

          design['LargeHeadline'].scopes[key].scopes[container] = {
            rules: containerData.rules || {},
            scopes: {
              ...containerScopes,
              [`Colorway${colorway.colorway}`]: {
                rules: {
                  ...containerColorwayScope.rules,
                  fontColor: value,
                },
              },
            },
          };
        }
      });
    });
  });

  [
    'CoupleVerticalNamesSeparator',
    'EventDateHeading',
    'EventDate',
    'Navbar',
  ].forEach((key) => {
    const rules = get(dekuraData, `typography.header.${key}`);

    if (rules && rules.lineHeightFactor) {
      rules.lineHeight = rules.lineHeightFactor;
    }

    design[key] = {
      rules: rules || {},
      scopes: {},
    };

    if (key !== 'Navbar') {
      (dekuraData.colorways || []).map((colorway) => {
        const value = get(colorway, `required.header.${key}`);

        if (value) {
          const scopeData = design[key].scopes[`Colorway${colorway.colorway}`] || {
            rules: {},
          };
          const scopeRules = scopeData.rules || {};

          design[key].scopes[`Colorway${colorway.colorway}`] = {
            ...scopeData,
            rules: {
              ...scopeRules,
              fontColor: value,
            },
          };
        }

        containers.forEach((container) => {
          const value = get(colorway, `header.${container}.${key}`);

          if (value) {
            const containerData = design[key].scopes[container] || {};
            const containerScopes = containerData.scopes || {};
            const containerColorwayScope = containerScopes[`Colorway${colorway.colorway}`] || {
              rules: {},
            };

            design[key].scopes[container] = {
              ...containerData,
              scopes: {
                ...containerScopes,
                [`Colorway${colorway.colorway}`]: {
                  rules: {
                    ...containerColorwayScope.rules,
                    fontColor: value,
                  },
                },
              },
            };
          }
        });
      });
    }
  });

  [
    'TopNavbar',
    'InlineNavbar',
  ].forEach((key) => {
    const scope = key === 'TopNavbar' ? 'Top' : 'Inline';

    design['Navbar'].scopes[scope] = {
      scopes: {},
    };
    (dekuraData.colorways || []).map((colorway) => {
      design['Navbar'].scopes[scope].scopes[`Colorway${colorway.colorway}`] = {
        rules: get(colorway, `header.${key}`) || {},
      };
    });
  });

  containers.forEach((key) => {
    const existing = design[key] || {};

    design[key] = {
      rules: existing.rules || {},
      scopes: existing.scopes || {},
    };

    const props = [
      'backgroundGraphic',
      'backgroundGraphicAlign',
      ...backgroundProps,
    ];

    (dekuraData.colorways || []).map((colorway) => {
      const containerScopeData = design[key].scopes[`Colorway${colorway.colorway}`] || {
        rules: {},
      };

      design[key].scopes[`Colorway${colorway.colorway}`] = {
        ...containerScopeData,
        rules: {
          ...containerScopeData.rules,
          ...pick(get(colorway, `header.${key}`), props),
        },
      };
    });
  });

  [
    'SmallDivider',
    'SmallGraphic',
    'LargeDivider',
    'LargeGraphic',
    'MobileLargeGraphic',
  ].forEach((key) => {
    design[key] = {
      rules: {},
      scopes: {},
    };

    (dekuraData.colorways || []).map((colorway) => {
      const value = get(colorway, `required.artwork.${key}`);

      if (value) {
        design[key].scopes[`Colorway${colorway.colorway}`] = {
          rules: {
            graphic: value,
          },
        };
      }
    });
  });

  return design;
};
