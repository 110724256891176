import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import LegacyRSVPForm from './LegacyRSVPForm';
import {
  ContentText, ContentWrapper, HeadlineSmall,
} from '../../content/components';
import { SITE_TYPES } from '../../core/constants';
import RSVPForm from '../../guest-management/forms/RSVPForm/RSVPForm';
import RSVPSearchForm from '../../guest-management/forms/RSVPSearchForm/RSVPSearchForm';
import { WrapperLarge } from '../../layout/website';
import Page from '../../pages/website/Page';
import { selectSite } from '../../website/selectors';

const style = (textAlignment) => css`
  max-width: 500px;
  ${theme.media.greaterThan(theme.breakpoints.small)(`
    margin:
      20px
      ${textAlignment === 'left' || textAlignment === 'center' ? 'auto' : '20px'}
      20px
      ${textAlignment === 'right' || textAlignment === 'center' ? 'auto' : '20px'};
  `)}
`;

const rsvpPageStyles = {
  cookieError: css`
    ${theme.typeStyles.bodyMediumStyles};
    text-align: center;
    color: ${theme.colors.negative800};
    margin-bottom: ${theme.spacing.x2};
  `,
};

const RSVP = ({
  configuration,
  id,
  isDemoSite,
  isPreview,
  isUsingGuestManagement,
  removeSpacing = false,
  siteType,
  title,
}) => {
  const [
    rsvpData,
    setRsvpData,
  ] = useState(null);
  const isInBrowser = typeof window !== 'undefined';
  const hasCookies = isInBrowser && window.navigator.cookieEnabled;

  return (
    // This has to exist way up here to avoid dealing with inherited styling
    isUsingGuestManagement && rsvpData
      ? (
        <RSVPForm
          contact={rsvpData.contact}
          events={rsvpData.events}
          guests={rsvpData.guests}
          siteType={siteType}
        />
      )
      : (
        <Page
          id={id}
          removeSpacing={removeSpacing}
          title={rsvpData ? '' : title}
        >
          <WrapperLarge>
            <ContentWrapper
              extraCss={
                [
                  style(configuration.textAlignment),
                ]
              }
              imageAlignment="top"
              textAlignment={configuration.textAlignment}
            >
              {
                isInBrowser && (
                  <>
                    <ContentText textAlignment={configuration.textAlignment}>
                      <HeadlineSmall>
                        Enter Your Name to RSVP
                      </HeadlineSmall>
                    </ContentText>
                    {
                      hasCookies
                        ? (
                          isUsingGuestManagement
                            ? (
                              <RSVPSearchForm
                                isReadOnly={isPreview || isDemoSite}
                                setRsvpData={setRsvpData}
                              />
                            )
                            : (
                              <LegacyRSVPForm
                                isReadOnly={isPreview || isDemoSite}
                              />
                            )
                        )
                        : (
                          <div css={rsvpPageStyles.cookieError}>
                            Cookies are required to submit your RSVP. Please enable cookies in your browser.
                          </div>
                        )
                    }
                  </>
                )
              }
            </ContentWrapper>
          </WrapperLarge>
        </Page>
      )
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    isDemoSite: site.isDemoSite,
    isUsingGuestManagement: site.isUsingGuestManagement || site.siteType === SITE_TYPES.ONLINE_INVITATION,
    siteType: site.siteType,
  };
};

export default connect(mapStateToProps)(RSVP);
