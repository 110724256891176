import { css } from '@emotion/react';
import {
  Button,
  Logo,
  styleUtils, TextLink,
  theme,
} from '@minted/minted-components';
import config from 'config';
import React from 'react';
import { useState } from 'react';

import { ONLINE_INVITATION_SOURCE_PARAM } from './constants';
import OnlineInvitationExclusions from './OnlineInvitationExclusions';

const onlineInvitationSignupStyles = {
  descriptionStyles: css`
    ${theme.typeStyles.bodySmallStyles}

    margin: auto;
    margin-bottom: ${theme.spacing.x2};
    text-align: center;
`,
  inputWrapperStyles: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${theme.spacing.x3};
`,
  logoWrapperStyles: css`
    width: ${styleUtils.rem(60)};
    margin: auto;
`,
  seeDetailsStyles: css`
    margin: auto;
`,
  signupWrapperStyles: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x4};
    background-color: ${theme.colors.notice200};
`,
};

const loginServiceHost = (
  config.get('loginServiceHost') ||
  `https://login.${window.location.href.split('/')[2].split('.').slice(1).join('.')}`
);

const cypressScope = 'signupCallout';

const OnlineInvitationSignup = () => {
  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  return (
    <div
      css={onlineInvitationSignupStyles.signupWrapperStyles}
      data-cy={cypressScope}
    >
      <div css={onlineInvitationSignupStyles.logoWrapperStyles}>
        <Logo modifier="minted" />
      </div>
      <div
        css={onlineInvitationSignupStyles.descriptionStyles}
        data-cy={`${cypressScope}Description`}
      >
        Sign up for a Minted account and receive a $25 credit towards your next purchase
      </div>
      <div
        css={onlineInvitationSignupStyles.inputWrapperStyles}
        data-cy={`${cypressScope}Button`}
      >
        <Button
          href={loginServiceHost + `/register?source=${ONLINE_INVITATION_SOURCE_PARAM}`}
          size={Button.sizes.small}
          target="_blank"
          text='Create an account'
          type={Button.types.secondary}
        />
      </div>
      <div
        css={onlineInvitationSignupStyles.seeDetailsStyles}
        data-cy={`${cypressScope}SeeDetails`}
      >
        <TextLink
          onClick={() => setIsModalOpen(true)}
          text="See details"
        />
      </div>
      <OnlineInvitationExclusions
        close={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </div>
  );
};

export default OnlineInvitationSignup;
