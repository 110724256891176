import { styleUtils } from '@minted/minted-components';

export const classicThemeData =
  {
    header: {},
    required: {},
  };

export const modernThemeData =
  {
    header: {},
    required: {
      LargeHeadline: {
        fontFamily: 'Lulo Clean',
        fontSizeFactor: 1,
        fontVariant: 'One',
        fontWeight: 400,
        lineHeight: 0.9,
        lineHeightFactor: 0.9,
      },
      MediumHeadline: {
        fontFamily: 'Lulo Clean',
        fontSizeFactor: 0.8,
        fontVariant: 'One',
        lineHeight: 1,
        lineHeightFactor: 1,
      },
      SmallHeadline: {
        fontFamily: 'Brandon Grotesque',
        fontSizeFactor: 1.25,
        fontVariant: 'Regular',
        fontWeight: 390,
        letterSpacing: 1.0,
        lineHeight: 1.1,
        lineHeightFactor: 1.1,
      },
      Text: {
        fontFamily: 'Brandon Grotesque',
        fontSizeFactor: 1.1,
        fontVariant: 'Regular',
        fontWeight: 390,
        lineHeight: 1.5,
        lineHeightFactor: 1.5,
      },
      TinyHeadline: {
        fontFamily: 'Brandon Grotesque',
        fontSizeFactor: 1.1,
        fontVariant: 'Regular',
        fontWeight: 390,
        lineHeight: 1.3,
        lineHeightFactor: 1.3,
      },
    },
  };

export const classicScriptThemeData =
  {
    header: {},
    required: {
      LargeHeadline: {
        fontFamily: 'Adora Bouton',
        fontSizeFactor: 1.9,
        fontStyle: 'normal',
        fontVariant: 'Regular',
        fontWeight: 400,
        lineHeight: 1.4,
        lineHeightFactor: 1.4,
      },
      MediumHeadline: {
        fontFamily: 'Adora Bouton',
        fontSizeFactor: 0.8,
        fontStyle: 'normal',
        fontVariant: 'Regular',
        lineHeight: 1.1,
        lineHeightFactor: 1.1,
      },
      SmallHeadline: {
        fontFamily: 'Futura Medium',
        fontSizeFactor: 1.25,
        fontStyle: 'normal',
        fontVariant: 'Regular',
        fontWeight: 400,
        lineHeight: 1.6,
        lineHeightFactor: 1.6,
      },
      Text: {
        fontFamily: 'Avenir',
        fontSizeFactor: 1.2,
        fontVariant: 'Regular',
        fontWeight: 400,
        lineHeight: 1.5,
        lineHeightFactor: 1.5,
      },
      TinyHeadline: {
        fontFamily: 'Avenir',
        fontSizeFactor: 1.2,
        fontVariant: 'Regular',
        lineHeight: undefined,
      },
    },
  };

export const modernScriptThemeData =
  {
    header: {},
    required: {
      LargeHeadline: {
        fontFamily: 'Aromatic',
        fontSizeFactor: 1.9,
        fontStyle: 'normal',
        fontVariant: 'Regular',
        fontWeight: 400,
        lineHeight: 1.4,
        lineHeightFactor: 1.4,
      },
      MediumHeadline: {
        fontFamily: 'Baskerville Book BQ',
        fontSizeFactor: 0.8,
        fontStyle: 'italic',
        fontVariant: 'Italic',
        letterSpacing: 1.0,
        lineHeight: 1.1,
        lineHeightFactor: 1.1,
      },
      SmallHeadline: {
        fontFamily: 'Baskerville Book BQ',
        fontSizeFactor: 1.25,
        fontStyle: 'italic',
        fontVariant: 'Italic',
        fontWeight: 400,
        letterSpacing: 1.0,
        lineHeight: 1.6,
        lineHeightFactor: 1.6,
      },
      Text: {
        fontFamily: 'Baskerville Book BQ',
        fontSizeFactor: 1.2,
        fontVariant: 'Regular',
        fontWeight: 400,
        lineHeight: 1.5,
        lineHeightFactor: 1.5,
      },
      TinyHeadline: {
        fontFamily: 'Baskerville Book BQ',
        fontSizeFactor: 1.2,
        fontVariant: 'Regular',
        lineHeight: undefined,
      },
    },
  };

export const HOLIDAY_WEBSITE_CONTENT_WIDTH = styleUtils.rem(600);

// Time in milliseconds to delay video display
export const VIDEO_PROCESSING_WAIT_TIME = 300000;

export const DIGITAL_WEDDING_WEBSITE_PK = 'DWW';
