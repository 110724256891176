import { format as formatDate } from 'date-fns';
import React from 'react';

export const formatAddress = ({
  address1, address2, city, state, zipCode,
}) => `${address1}, ${address2 ? address2 + ', ' : ''} ${city}, ${state} ${zipCode}`;

export const formatEventDate = (date) => {
  // incoming date format: 'MM/dd/yyyy'
  const [
    month,
    day,
    year,
  ] = date.split('/');

  if (!(day && month && year)) {
    return '';
  }

  const actualDate = new Date(Number(year), Number(month) - 1, Number(day));

  return formatDate(actualDate, 'EEEE, MMMM d, yyyy');
};

export const getEventDateTimeComponent = (date, time, endDate, endTime) => {
  // Incoming date format: 'MM/dd/yyyy'

  // Date is required to be valued
  if (!date) {
    return null;
  }

  return (
    <div>
      <div>
        {
          endDate && date !== endDate ? `${formatEventDate(date)} - ${formatEventDate(endDate)}` : `${formatEventDate(date)}`
        }
      </div>
      {
        time && (
          <div>
            {endTime && (date !== endDate || time !== endTime) ? `${time} - ${endTime}` : `${time}`}
          </div>
        )
      }
    </div>
  );
};
