import { css } from '@emotion/react';
import {
  Button, styleUtils, theme, TextInput, IconButton, Icon,
} from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { emailRegexp } from '../../website/validations';

const eventSummaryEmailStyles = {
  emailButtonContainer: css`
    min-width: ${styleUtils.rem(228)};

    ${theme.media.lessThan(theme.breakpoints.small)(`
      width: 100%;
    `)};
  `,
  emailContainer: css`
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2};

    ${theme.media.lessThan(theme.breakpoints.small)(`
      flex-direction: column;
    `)};
  `,
  emailInputContainer: css`
    width: 100%;
  `,
  emailsContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
    width: 100%;

    ${theme.media.lessThan(theme.breakpoints.small)(`
      gap: 0;
    `)};
  `,
  panel: css`
    box-shadow: 0 4px 12px 0 #33333326;
    background-color: ${theme.colors.gray100};
    color: ${theme.colors.gray700};
    border: 1px solid ${theme.colors.border};
    padding-top: ${theme.spacing.x6};
    padding-bottom: ${theme.spacing.x6};
    padding-left: ${theme.spacing.x8};
    padding-right: ${theme.spacing.x8};
    gap: ${theme.spacing.x4};
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    box-sizing: border-box;
    ${theme.media.lessThan(theme.breakpoints.small)(`
        padding: ${theme.spacing.x4};
    `)};
  `,
  panelSection: css`
    text-align: left;
  `,
};

const propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateEmails: PropTypes.func.isRequired,
};

const EventSummaryEmail = ({
  emails, eventCount, isSubmitting, updateEmails,
}) => {
  const validateEmail = (email) => emailRegexp.test(email);

  const handleEmailChange = (index, newEmail) => {
    const updatedEmails = [
      ...emails,
    ];

    updatedEmails[index].value = newEmail;
    updatedEmails[index].isValid = validateEmail(newEmail);
    updateEmails(updatedEmails);
  };

  const handleAddEmail = () => {
    updateEmails([
      ...emails,
      {
        isValid: false,
        value: '',
      },
    ]);
  };

  const removeEmail = useCallback((emailIndex) => {
    updateEmails(emails.filter((email, index) => index !== emailIndex));
  }, [
    emails,
    updateEmails,
  ]);

  return (
    <div css={eventSummaryEmailStyles.panel}>
      <div css={eventSummaryEmailStyles.panelSection}>
        <span css={theme.typeStyles.displayExtraExtraSmallStyles}>
          Send me a copy of
          {eventCount > 1 ? ' all  ' : ' '}
          my responses
        </span>
        <div css={theme.typeStyles.bodySmallStyles}>
          Get a summary of
          {eventCount > 1 ? ' these events ' : ' this event '}
          and a day-of reminder email delivered to your inbox.
        </div>
      </div>
      <div css={eventSummaryEmailStyles.emailsContainer}>
        {
          emails.map(({
            isValid, value,
          }, index) => (
            <div
              css={eventSummaryEmailStyles.emailContainer}
              key={`rsvp_email_${index}`}
            >
              <div css={eventSummaryEmailStyles.emailInputContainer}>
                <TextInput
                  addons={
                    index > 0
                      ? [
                        {
                          align: 'right',
                          content:
                        <IconButton
                          iconType={Icon.types.close}
                          onClick={()=> removeEmail(index)}
                          type={IconButton.types.secondary}
                        />,
                        },
                      ]
                      : []
                  }
                  disabled={isSubmitting}
                  error={isValid ? '' : 'Invalid email address'}
                  inputMode='email'
                  label='Email (optional)'
                  name={`email${index}`}
                  onChange={(event) => handleEmailChange(index, event.target.value)}
                  touched={value !== ''}
                  value={value}
                />
              </div>
              <div css={eventSummaryEmailStyles.emailButtonContainer}>
                {
                  (index === emails.length - 1 && isValid) && (
                    <Button
                      disabled={isSubmitting}
                      expand
                      iconAlignment="left"
                      iconType="add"
                      onClick={handleAddEmail}
                      size="large"
                      text="ADD ANOTHER EMAIL"
                      type={Button.types.secondary}
                    />
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

EventSummaryEmail.propTypes = propTypes;

export default EventSummaryEmail;
