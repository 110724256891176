import { GlobalStyles } from '@minted/minted-components';
import React from 'react';
import { connect } from 'react-redux';

import {
  getAllResources, getResource, filterPage, selectSite,
} from '../../selectors';
import FancyClothesProvider from '../FancyClothesProvider';
import Footer from '../Footer';
import WebsiteHeader from '../WebsiteHeader';
import WebsitePages from '../WebsitePages';

const OnlineInvitationSite = ({
  pages,
  resources,
  route,
  site,
  themeAuthor,
}) => {
  const filteredPages = pages.filter(
    (page) => filterPage(
      page,
      {
        ...resources,
        sites: [
          site,
        ],
      }
    )
  );

  return (
    <FancyClothesProvider>
      <GlobalStyles />
      <div id="home">
        <WebsiteHeader
          condensed={false}
          pages={filteredPages}
        />
      </div>
      <WebsitePages
        pages={filteredPages}
        resources={resources}
        site={site}
      />
      <Footer
        artist={themeAuthor}
        siteType={site.siteType}
      />
    </FancyClothesProvider>
  );
};
const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  const pages = getResource(state.resources.pages);
  const resources = getAllResources(state.resources);

  return {
    pages,
    resources,
    route: state.router.route,
    site,
    themeAuthor: state.theme.attribution,
  };
};

export default connect(mapStateToProps)(OnlineInvitationSite);
