import { css } from '@emotion/react';
import {
  Button,
  DesignProvider,
  Input,
} from '@minted/fancyclothes';
import {
  styleUtils, theme, Divider,
} from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { SITE_TYPES } from '../../core/constants';
import {
  Column,
  Row,
  WrapperLarge,
} from '../../layout/website';
import Page from '../../pages/website/Page';
import { DEFAULT_COLORWAY } from '../../themes/constants';
import { authenticateGuest } from '../../website/actions';
import StyledTopBar from '../../website/components/StyledTopBar';
import { createRoutes } from '../../website/router';
import { filterPage, selectSite } from '../../website/selectors';

const style = {
  alert: {
    backgroundColor: '#f7e0e0',
    border: '1px solid #e6c0c3',
    borderRadius: '4px',
    color: '#af4150',
    marginBottom: '30px',
    padding: '20px 0',
  },
  containerDivider: css`
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.x2};
    margin-bottom: ${theme.spacing.x8};
  `,
  divider: css`
    width: 15%;
    ${theme.media.lessThan(theme.breakpoints.medium)(`
      width: 30%;
    `)}
  `,
  wrapper: css`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    ${theme.media.greaterThan(theme.breakpoints.small)(`
      width: 500px;
    `)}
  `,
};

const bottomTextContainerStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${theme.spacing.x12};
`;

const bottomTextStyles = css`
  margin-bottom: ${theme.spacing.x12};
  width: 100%;
  text-align: center;
  ${theme.media.greaterThan(theme.breakpoints.small)(`
    width: 500px;
  `)}
`;

const loginContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const loginContainerStylesOnlineInvitation = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  ${theme.media.greaterThan(theme.breakpoints.small)(`
    justify-content: center;
  `)}
`;

const inputContainerStyles = css`
  margin-bottom: ${styleUtils.rem(20)};
  input {
    font-size: 16px;
  }
`;

const cookieMessageStyles = css`
  font-size: 20px;
  text-align: center;
  color: ${theme.colors.negative800};
`;

const disabledTextBoxStyle = css`
  ${inputContainerStyles}
  input {
    background-color: ${theme.colors.gray300};
  }
`;

class Login extends React.Component {
  constructor(props, context) {
    super(props);
    this.router = context.router;
    this.siteType = props.site.siteType;
    this.state = {
      error: null,
      isSubmitting: false,
      password: null,
    };
  }

  handleOnSubmit(event) {
    const {
      dispatch,
    } = this.props;

    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    dispatch(authenticateGuest(this.state.password)).then(({
      value,
    }) => {
      const {
        pages, sites,
      } = value;
      const next = this.router.getState().params.next;

      let pageTitle;
      const site = sites[0];

      // TODO: this should be an action or perhaps linked to the active route
      if (site.siteType === SITE_TYPES.WEDDING) {
        pageTitle = `${site.yourName || 'Name'} \u0026 ${site.spouseName || 'Name'}`;
      } else {
        pageTitle = site.welcomeHeading;
      }

      document.title = `${pageTitle} \u2014 Minted`;

      this.router.stop();
      const filteredPages = pages.filter(
        (page) => filterPage(
          page,
          value
        )
      );

      this.router.add(createRoutes(filteredPages));

      if (next && this.router.matchPath(next)) {
        this.router.start(next);
      } else {
        this.router.start('/');
      }
    }).catch((error) => {
      let message;

      if (error.response?.status === 400) {
        message = 'The provided password was incorrect';
      } else {
        message = 'There was a problem logging in, please try again';
      }
      this.setState({
        error: message,
        isSubmitting: false,
        password: null,
      });
      if (error.response?.status !== 400) {
        throw error;
      }
    });
  }

  render() {
    const {
      colorway, design,
    } = this.props;
    const isDisabled = this.state.isSubmitting || (typeof window !== 'undefined' && !window.navigator.cookieEnabled);

    let error;

    if (this.state.error) {
      error = (
        <div css={style.alert}>
          {this.state.error}
        </div>
      );
    }

    return (
      <DesignProvider
        colorway={colorway}
        design={design}
      >
        <div css={this.siteType === SITE_TYPES.ONLINE_INVITATION ? loginContainerStylesOnlineInvitation : loginContainerStyles}>
          <Page
            id="login"
            title="Please type in your password"
          >
            {this.state.isSubmitting ? <StyledTopBar /> : null}
            <WrapperLarge>
              <Row>
                <Column>
                  {
                    (typeof window !== 'undefined' && !window.navigator.cookieEnabled ) && (
                      <div css={cookieMessageStyles}>
                        Cookies are required to view this site. Please enable cookies in your browser.
                      </div>
                    )
                  }
                  <div css={style.containerDivider}>
                    <div css={style.divider}>
                      <Divider type="light" />
                    </div>

                  </div>
                  <div css={style.wrapper}>
                    <form onSubmit={this.handleOnSubmit.bind(this)}>
                      {error}
                      <div
                        css={isDisabled ? disabledTextBoxStyle : inputContainerStyles}
                      >
                        <Input
                          autocapitalize="off"
                          autocomplete="off"
                          autocorrect="off"
                          disabled={isDisabled}
                          onChange={
                            (event) => this.setState({
                              password: event.target.value,
                            })
                          }
                          spellcheck={false}
                          value={this.state.password || ''}
                        />
                      </div>
                      <Button
                        block={true}
                        color="dark"
                        disabled={isDisabled}
                        onClick={() => {}}
                        text="Submit"
                        type="primary"
                      />
                    </form>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column>
                  <div css={bottomTextContainerStyles}>
                    <div css={bottomTextStyles}>
                      If you don’t know the password, please ask the site owner for it.
                    </div>
                  </div>
                </Column>
              </Row>
            </WrapperLarge>
          </Page>
        </div>
      </DesignProvider>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    colorway: site.themeId ? DEFAULT_COLORWAY : state.theme.colorwayCodeById[site.mintedProductColorChoiceId],
    design: state.theme.fancyClothesData,
    site,
  };
};

export default connect(mapStateToProps)(Login);
