import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import startsWith from 'lodash/startsWith';
import transform from 'lodash/transform';

import { SITE_TYPES } from '../core/constants';
import { selectCollection } from '../resources/selectors';

export const getResource = (resource) => selectCollection(resource, {}).items.map(({
  data,
}) => data);

export const getAllResources = (resources) => {
  const events = getResource(resources.events);

  return {
    accommodations: getResource(resources.accommodations),
    attendants: getResource(resources.attendants),
    comments: getResource(resources.comments),
    guests: getResource(resources.guests),
    photos: getResource(resources.photos),
    publicEvents: events.filter((event) => !event.private),
    registries: getResource(resources.registries),
    rsvpEvents: events.filter((event) => event.rsvpEnabled),
    videos: getResource(resources.videos),
  };
};

export const filterPage = (page, data) => {
  if (!page.isVisible) {
    return false;
  }
  const site = data.sites[0];

  switch (page.slug) {
    case 'events':
      return !isEmpty(
        data.publicEvents === undefined
          ? data.events.filter((event) => !event.private)
          : data.publicEvents
      );

    case 'accommodations':
      return !isEmpty(data.accommodations);

    case 'wedding-party':
      return !isEmpty(data.attendants);

    case 'photos':

      return !isEmpty(page.sections);
    case 'rsvp':
      return site.hasRsvpEvents;

    case 'registry':
      return !isEmpty(data.registries);

    case 'about':
      return false;

    default:
      return true;
  }
};

export const selectSite = (sites) => {
  const collection = selectCollection(sites, {});

  return (collection.items.length > 0) ? collection.items[0].data : null;
};

export const selectAsset = (resources, assetName, version) => {
  const site = selectSite(resources.sites);
  const uuid = site.assets ? site.assets[assetName] : null;

  // If asset value is a URL instead of a UUID, return it
  if (startsWith(uuid, 'http') || startsWith(uuid, '//')) {
    return uuid;
  }

  const images = selectCollection(resources['image-uploads'], {});
  const result = find(images.items, (image) => (
    image.data.uuid === uuid
  ));

  return get(result, `data.versions.${version}`, null);
};

export const selectAssets = (resources) => {
  const site = selectSite(resources.sites);
  const images = selectCollection(resources['image-uploads'], {})
    .items.map((item) => item.data);
  const keyedImages = keyBy(images, 'uuid');

  return transform(site.assets, (result, value, key) => {
    result[key] = keyedImages[value];
  }, {});
};

export const selectGuestSitePageTitle = (site) => {
  if (site.siteType === SITE_TYPES.WEDDING) {
    return `${site.yourName || 'Name'} &amp; ${site.spouseName || 'Name'}`;
  } else if (site.siteType === SITE_TYPES.ONLINE_INVITATION) {
    return site.eventHeading;
  }

  return site.welcomeHeading;
};
