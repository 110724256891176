import { isEmpty, sortBy } from 'lodash';

import { SITE_TYPES } from '../../core/constants';

const defaultEventFilterOption = {
  id: '',
  label: 'All Events Summary',
};

export const getDashboardData = (listOfEvents, listOfGuests, listOfInvites, listOfRSVPs, partyDataByContactId, site) => {
  // Returns an object of data needed to display on guest dashboard.

  const isOnlineInvitation = site.siteType === SITE_TYPES.ONLINE_INVITATION;
  const eventCount = listOfEvents.length;
  let totalGuestCount = 0;
  const contactIds = new Set();
  const hiddenGuestIds = new Set();

  for (const guest of listOfGuests) {
    if (partyDataByContactId[guest.data.addressbookContactId]) {
      contactIds.add(guest.data.addressbookContactId);
      totalGuestCount++;
    } else {
      hiddenGuestIds.add(guest.data.id);
    }
  }
  const partyCount = contactIds.size;

  if (!eventCount) {
    // If events do not exist, we don't need to display the event list header. We return an empty array of event info.
    return {
      eventCount: 0,
      eventFilterOptions: [],
      partyCount,
      totalGuestCount,
    };
  }

  // Create a mapping of event ID to guest count
  const eventGuestCounts = {};

  for (const invite of listOfInvites) {
    if (hiddenGuestIds.has(invite.data.guest)) {
      continue;
    }

    if (!eventGuestCounts[invite.data.event]) {
      eventGuestCounts[invite.data.event] = 0;
    }
    eventGuestCounts[invite.data.event]++;
    // Returns eventGuestCounts which will be the guest count for the specified event id
    // Example:
    // {
    //   1: 17
    // }
  }

  // We only have on event for online invitation sites, so we set it to an empty array and it will have
  // up to one event as event filter options.
  const eventFilterOptions = isOnlineInvitation
    ? []
    : [
      defaultEventFilterOption,
    ];

  const rsvpTotals = {};

  for (const event of listOfEvents) {
    const guestCount = eventGuestCounts[event.data.id] || 0;
    let numberAttending = 0;
    let numberNotAttending = 0;
    let numberOfInvitesSent = 0;
    let numberOfInvitesNotSent = 0;

    if (isOnlineInvitation) {
      // No need to loop invites for data that only online invitation sites are using
      for (const invite of listOfInvites) {
        if (invite.inviteSent) {
          numberOfInvitesSent++;
        } else {
          numberOfInvitesNotSent++;
        }
      }
    }

    for (const rsvp of listOfRSVPs) {
      if (hiddenGuestIds.has(rsvp.data.guest.id)) {
        continue;
      }
      if (rsvp.data.event.id === event.data.id) {
        if (rsvp.data.attending) {
          numberAttending++;
        } else {
          numberNotAttending++;
        }
      }
    }

    rsvpTotals[`${event.data.id}`] = {
      numberAttending,
      numberAwaitingReply: Math.max(guestCount - numberAttending - numberNotAttending, 0),
      numberNotAttending,
      numberOfInvitesNotSent,
      numberOfInvitesSent,
    };

    eventFilterOptions.push({
      id: `${event.data.id}`,
      // If isOnlineInvitation is true, use site.eventHeading, otherwise use event title
      // Append guest count if available
      label: isOnlineInvitation
        ? `${site.eventHeading}${guestCount ? ` (${guestCount})` : ''}`
        : `${event.data.title}${guestCount ? ` (${guestCount})` : ''}`,
    });
  }

  return {
    eventCount,
    eventFilterOptions,
    partyCount,
    rsvpTotals,
    totalGuestCount,
  };
};

export const getPartyData = (guests, invites, partyDataByContactId, rsvps) => {
  // Can't display the party data without the contact data loaded
  if (isEmpty(partyDataByContactId)) {
    return [];
  }

  const rsvpsByGuestId = rsvps.reduce((obj, rsvp) => {
    /**
     * Creates structure like
     * {
     *   <guestId>: {
     *     <eventId>: {
     *       "answers": [],
     *       "attending": true,
     *       "id": <rsvpId>
     *     },
     *     <eventId2>: . . .,
     *   },
     *   <guestId2>: . . .,
     * }
     */
    if (obj[rsvp.data.guest.id] === undefined) {
      obj[rsvp.data.guest.id] = {};
    }

    obj[rsvp.data.guest.id][rsvp.data.event.id] = {
      answers: rsvp.data.answers,
      attending: rsvp.data.attending,
      id: rsvp.data.id,
    };

    return obj;
  }, {});

  const invitesByGuestId = invites.reduce((obj, invite) => {
  /**
     * Creates structure like
     * {
     *   <guestId>: [<invite1>, <invite2>],
     *   <guestId2>: . . .,
     * }
     */
    if (invite.delete) {
      return obj;
    }

    if (obj[invite.data.guest] === undefined) {
      obj[invite.data.guest] = [];
    }

    obj[invite.data.guest].push(invite.data);

    return obj;
  }, {});

  const guestDataByContactId = guests.reduce((obj, guest) => {
    /**
     * Creates structure like this, sorted by sortKey
     * [
     *   {
     *     addressbookContactId: <contactId>,
     *     email: <email>,
     *     contactName: <contactName>,
     *     sortKey: <sortyKey>,
     *     guests: [
     *       {
     *         "firstName": <firstName>,
     *         "lastName": <lastName>,
     *         "id": <guestId>,
     *         "invites": [<invite1, invite2>],
     *         "rsvps": {
     *           <eventId>: {
     *             "answers": [],
     *              "attending": true,
     *               "id": <rsvpId>
     *           },
     *           <eventId2>: . . .,
     *         }
     *       }
     *     ]
     *   },
     *   {
     *     . . .,
     *   },
     * ]
     */
    if (guest.delete) {
      return obj;
    }

    if (obj[guest.data.addressbookContactId] === undefined) {
      const sortedPartyData = partyDataByContactId[guest.data.addressbookContactId];

      if (isEmpty(sortedPartyData)) {
        // Short circuit if missing addressbook data
        return obj;
      }
      obj[guest.data.addressbookContactId] = {
        contactName: sortedPartyData.name,
        email: sortedPartyData.email,
        guests: [],
        sortKey: sortedPartyData.sortKey,
        // eslint-disable-next-line sort-keys
        address1: sortedPartyData.address1,
        address2: sortedPartyData.address2,
        city: sortedPartyData.locality,
        country: sortedPartyData.country,
        state: sortedPartyData.administrativeArea,
        zipCode: sortedPartyData.postalCode,
      };
    }

    obj[guest.data.addressbookContactId].guests.push({
      firstName: guest.data.firstName,
      id: guest.data.id,
      invites: invitesByGuestId[guest.data.id] ?? [],
      lastName: guest.data.lastName,
      rsvps: rsvpsByGuestId[guest.data.id] ?? {},
    });

    return obj;
  }, {});

  const sortedPartyData = [];

  for (const [
    key,
    value,
  ] of Object.entries(guestDataByContactId)) {
    const partySize = value.guests.length;

    // "Flatten" object to a list
    sortedPartyData.push({
      addressbookContactId: key,
      partySize,
      ...value,
    });
  }

  return sortBy(sortedPartyData, (item) => item.sortKey, [
    'desc',
  ]);
};

export const filterPartyData = (
  sortedPartyData,
  searchTerm,
  selectedEvent,
  hasRepliedFilter,
  hasContactInfoFilter,
  rsvpStatusFilter,
  answersFilter
) => {
  const filteredSortedPartyData = [];

  for (const partyData of sortedPartyData) {
    // Creating a copy, because modifying the original entry means we can't
    // un-filter after when they're cleared
    const filteredEntry = {
      ...partyData,
    };

    // Do search first before any event specific filtering
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const contactNameMatches = filteredEntry.contactName.toLowerCase().indexOf(lowerSearchTerm) > -1;

      if (!contactNameMatches) {
        // Contact name does not match search term, check guests
        const hasMatchingGuest = filteredEntry.guests.some(
          (guest) => guest.firstName?.toLowerCase().indexOf(lowerSearchTerm) > -1 ||
            guest.lastName?.toLowerCase().indexOf(lowerSearchTerm) > -1
        );

        if (!hasMatchingGuest) {
          // No guests match search term either, do not add party to list
          continue;
        }
      }

      if (selectedEvent && !contactNameMatches) {
        // Contact doesn't match, only show matching guests when event is selected
        filteredEntry.guests = filteredEntry.guests.filter(
          (guest) => guest.firstName?.toLowerCase().indexOf(lowerSearchTerm) > -1 ||
            guest.lastName?.toLowerCase().indexOf(lowerSearchTerm) > -1
        );
      }
    }

    // Filter out guests/contacts not invited to the event
    if (selectedEvent) {
      // If no guests are invited, skip this contact
      if (!filteredEntry.guests.some((guest) => guest.invites.findIndex((invite) => Number(selectedEvent) === invite.event) > -1)) {
        continue;
      }

      // Modify the guest list to only show invited guests
      filteredEntry.guests = filteredEntry.guests.filter(
        (guest) => guest.invites.findIndex((invite) => Number(selectedEvent) === invite.event) > -1
      );

      // Check RSVP filters
      if (rsvpStatusFilter) {
        let filterComparison = undefined;

        if (rsvpStatusFilter === ATTENDING) {
          filterComparison = true;
        } else if (rsvpStatusFilter === NOT_ATTENDING) {
          filterComparison = false;
        }
        filteredEntry.guests = filteredEntry.guests.filter(
          (guest) => guest.rsvps[selectedEvent]?.attending === filterComparison
        );
      }
      if (!isEmpty(answersFilter)) {
        for (const questionId of Object.keys(answersFilter)) {
          const answerData = answersFilter[questionId];

          // Don't filter if the value to be filtered on doesn't exist
          if (isEmpty(answerData)) {
            continue;
          }

          // Multiple choice, check if answer is in the list
          filteredEntry.guests = filteredEntry.guests.filter(
            (guest) => {
              const rsvp = guest.rsvps[selectedEvent];

              // Guest must RSVP attending in order to answer questions
              if (!rsvp || !rsvp.attending) {
                return false;
              }

              const answer = rsvp.answers.find((answer) => answer.question === Number(questionId))?.text;

              if (Array.isArray(answerData)) {
                // Multiple choice, check that guest answer is in answer data
                return answerData.includes(answer);
              }

              // Open ended-check that guest answer has a value
              return (answerData === 'answered' && !!answer) || (answerData === 'unanswered' && !answer);
            }
          );
        }
      }
    } else {
      // Check summary filters
      if (hasRepliedFilter) {
        // Need to calculate each individually because a party can be updated
        // after RSVP to require an additional reply and fit both criteria
        const partyHasReplied = partyData.guests.some((guest) => !isEmpty(guest.rsvps));
        const partyAwaitingReply = partyData.guests.some((guest) => isEmpty(guest.rsvps));

        // Filter out those not matching reply filter status
        if (
          (hasRepliedFilter === 'hasReply' && !partyHasReplied) ||
          (hasRepliedFilter === 'awaitingReply' && !partyAwaitingReply)
        ) {
          continue;
        }
      }
      if (hasContactInfoFilter) {
        // Filter out those not matching reply filter status
        if (
          (hasContactInfoFilter === 'hasEmail' && !partyData.email) ||
          (hasContactInfoFilter === 'hasAddress' && !partyData.address1)
        ) {
          continue;
        }
      }
    }

    filteredSortedPartyData.push(filteredEntry);
  }

  return filteredSortedPartyData;
};

// Used for EmailGuestsModal
const ATTENDING = 'attending';
const NOT_ATTENDING = 'notAttending';
const AWAITING_REPLY = 'awaitingReply';

export const getGuestManagementAttendanceByEventId = (events, invites, rsvps) => {
  const eventGuestMap = invites.reduce((acc, invite) => {
    const eventId = invite.data.event;
    const guestId = invite.data.guest;

    if (!acc[eventId]) {
      acc[eventId] = [];
    }
    acc[eventId].push(guestId);

    return acc;
  }, {});

  const eventIdMap = (
    events
      ? events.reduce((acc, event) => {
        acc[event.data.id] = {};

        return acc;
      }, {})
      : {}
  );

  const rsvpStatusByEventIdAndGuestId = rsvps.reduce((obj, rsvp) => {
    if (obj[rsvp.data.event.id]) {
      obj[rsvp.data.event.id][rsvp.data.guest.id] = rsvp.data.attending ? ATTENDING : NOT_ATTENDING;
    }

    return obj;
  }, eventIdMap);

  return events.reduce((acc, event) => {
    const eventId = event.data.id;
    const guests = eventGuestMap[eventId] || [];

    acc[eventId] = guests.reduce((guestAcc, guestId) => {
      const rsvpStatus = rsvpStatusByEventIdAndGuestId[eventId][guestId];

      guestAcc[guestId] = rsvpStatus || AWAITING_REPLY;

      return guestAcc;
    }, {});

    return acc;
  }, {});
};

export const getEmailModalData = (events, invites, rsvps, site, sortedPartyData) => {
  const names = `${site.yourName} & ${site.spouseName}`;
  const attendanceByEventId = getGuestManagementAttendanceByEventId(events, invites, rsvps);
  const rsvpLink = `http://${site.domain}/rsvp`;

  const guestsWithContactNameSet = [];

  for (const partyData of sortedPartyData) {
    const lastSpaceIndex = partyData.contactName.lastIndexOf(' ');
    let firstName = '';
    let lastName = '';

    if (lastSpaceIndex > -1) {
      firstName = partyData.contactName.substring(0, lastSpaceIndex);
      lastName = partyData.contactName.substring(lastSpaceIndex + 1);
    } else {
      lastName = partyData.contactName;
    }

    guestsWithContactNameSet.push({
      data: {
        addressbookContactId: partyData.addressbookContactId,
        email: partyData.email,
        firstName,
        id: partyData.guests[0].id,
        lastName,
      },
    });
  }

  const eventsWithGuestsSet = [];

  // Iterate over each event and populate its `data.guests` field
  for (const event of events) {
    const invitedGuestIds = new Set();

    for (const invite of invites) {
      if (invite.data.event === event.data.id) {
        invitedGuestIds.add(invite.data.guest);
      }
    }

    eventsWithGuestsSet.push({
      data: {
        ...event.data,
        guests: [
          ...invitedGuestIds,
        ].map((guestId) => guestsWithContactNameSet.find((guest) => guest.data.id === guestId)).filter((guest) => !!guest),
      },
    });
  }

  return {
    attendanceByEventId,
    events: eventsWithGuestsSet,
    guests: guestsWithContactNameSet,
    invites,
    names,
    rsvpLink,
    rsvps,
  };
};

export const isGuestNamed = (guest) => !isEmpty(guest.firstName?.trim()) && !isEmpty(guest.lastName?.trim());
