import { css } from '@emotion/react';
import {
  Button,
  Divider,
  FadeReveal,
  theme,
} from '@minted/minted-components';
import isEqual from 'lodash/isEqual';
import { number, bool } from 'prop-types';
import React, { useState } from 'react';
import { createEditor } from 'slate';
import {
  Editable,
  Slate,
  withReact,
} from 'slate-react';

import { EVENT_DETAILS_SPACING } from './constants';
import { BLANK_SLATE_DOCUMENT } from '../../core/constants';
import { getEventDateTimeComponent } from '../../events/utils';
import { eventPropTypes } from '../../global/propTypes';

const eventDetailsStyles = {
  address: css`
    color: ${theme.colors.textSecondary};

    ${theme.typeStyles.bodySmallStyles};
  `,
  counterText: css`
    color: ${theme.colors.textSecondary};

    ${theme.typeStyles.bodyExtraSmallStyles};
  `,
  dateTime: css`
    color: ${theme.colors.textSecondary};

    ${theme.typeStyles.captionSmallStyles};
  `,
  description: css`
    text-align: left;
    color: ${theme.colors.textSecondary};
    ${theme.typeStyles.bodyExtraSmallStyles};
  `,
  title: css`
    ${theme.typeStyles.displayExtraSmallStyles};
  `,
  wrapper: css`
    display: grid;
    gap: ${theme.spacing.x3};
    text-align: center;
  `,
};

const EventDetails = ({
  event, index, isMobile, readOnly, totalEvents,
}) => {
  const [
    isDescriptionToggled,
    setIsDescriptionToggled,
  ] = useState(false);

  const editor = withReact(createEditor());

  const onViewDetailsClick = () => {
    setIsDescriptionToggled((prev) => (!prev));
  };

  const buttonLabel = `${isDescriptionToggled ? 'Hide' : 'Show'} Event details`;
  const eventsCounterLabel = `${index + 1} of ${totalEvents} events`;
  const isFirstEvent = index === 0;
  const {
    margin,
    padding,
  } = EVENT_DETAILS_SPACING[readOnly ? 'summary' : 'form'][isMobile ? 'mobile' : 'desktop'];
  const wrapperSpacing = css`
    padding-top: ${readOnly && !isFirstEvent ? theme.spacing.x4 : padding.top};
    padding-bottom: ${readOnly && !isFirstEvent ? theme.spacing.x2 : padding.bottom};
    padding-left: ${padding.left};
    padding-right: ${padding.right};
    margin-top: ${margin.top};
    margin-bottom: ${margin.bottom};
    margin-left: ${margin.left};
    margin-right: ${margin.right};
  `;

  return (
    <div
      css={
        css`
          ${eventDetailsStyles.wrapper};
          ${wrapperSpacing}
        `
      }
    >
      {
        totalEvents > 1 && (
          <div css={eventDetailsStyles.counterText}>
            {eventsCounterLabel}
          </div>
        )
      }
      <div>
        <div css={eventDetailsStyles.title}>
          {event.title}
        </div>

        <div css={eventDetailsStyles.dateTime}>
          {getEventDateTimeComponent(event.date, event.time, event.endDate, event.endTime)}
        </div>
      </div>
      {
        event.hasPhysicalAddress && (
          <div css={eventDetailsStyles.address}>
            <div>
              {event.venue}
            </div>
            {event.address1}
            ,
            {' '}
            {event.address2 ? `${event.address2}, ` : ''}
            {event.city}
            ,
            {' '}
            {event.state}
            {' '}
            {event.zipCode}
          </div>
        )
      }

      {
        !isEqual(event.description, BLANK_SLATE_DOCUMENT) && (
          <>
            <FadeReveal
              in={isDescriptionToggled}
              unmountOnExit
            >
              <Divider
                spacing="small"
                type="light"
              />

              <div css={eventDetailsStyles.description}>
                <Slate
                  editor={editor}
                  initialValue={event.description}
                  key={event.id}
                  value={event.description}
                >
                  <Editable readOnly />
                </Slate>
              </div>
            </FadeReveal>

            <Button
              onClick={onViewDetailsClick}
              size="small"
              text={buttonLabel}
              type="tertiary"
            />
          </>
        )
      }

    </div>
  );
};

EventDetails.propTypes = {
  event: eventPropTypes,
  index: number.isRequired,
  readOnly: bool,
  totalEvents: number.isRequired,
};

EventDetails.defaultProps = {
  readOnly: false,
};

export default EventDetails;
