import { css } from '@emotion/react';
import {
  Modal,
  theme,
} from '@minted/minted-components';
import React from 'react';

const exclusionStyles = {
  detailsHeader: css`
    ${theme.typeStyles.titleLargeStyles}

    padding-bottom: ${theme.spacing.x4};
`,
  detailsText: css`
    ${theme.typeStyles.bodySmallStyles}

    display: flex;
`,
  modal: css`
    padding: ${theme.spacing.x16};

    ${theme.media.lessThan(theme.breakpoints.small)(css`
      padding: ${theme.spacing.x4};
    `)}
`,
};

const OnlineInvitationExclusions = ({
  close, isOpen,
}) => (
  <Modal
    mobileFullscreen={true}
    onCloseClick={close}
    open={isOpen}
    size={Modal.sizes.medium}
  >
    <div
      css={exclusionStyles.modal}
      data-cy='exclusionsModal'
    >
      <div css={exclusionStyles.detailsHeader}>
        Details and exclusions
      </div>
      <div css={exclusionStyles.detailsText}>
        Oh boy we got 'em
      </div>
    </div>
  </Modal>
);

export default OnlineInvitationExclusions;
