import {
  arrayOf,
  number,
  shape,
  string,
  oneOf,
  bool,
} from 'prop-types';

import {
  ATTENDING, NOT_ATTENDING, AWAITING_REPLY,
} from '../guests/constants';

export const eventPropTypes = shape({
  address1: string,
  address2: string,
  city: string,
  date: string.isRequired,
  description: arrayOf(shape({
    children: arrayOf(shape({
      text: string.isRequired,
    })),
    type: string.isRequired,
  })),
  endDate: string,
  endTime: string,
  id: number.isRequired,
  questions: arrayOf(shape({
    choices: arrayOf(string),
    id: number,
    questionType: oneOf([
      'freetext',
      'multiple_choice',
    ]),
    required: bool,
    text: string,
  })),
  state: string,
  time: string,
  title: string.isRequired,
  venue: string.isRequired,
  zipCode: string,
});

export const eventResponsesPropTypes = shape({
  answers: arrayOf(shape({
    answer: string,
    questionId: number,
  })).isRequired,
  eventId: number.isRequired,
  responseType: oneOf([
    ATTENDING,
    NOT_ATTENDING,
    AWAITING_REPLY,
  ]),
});
