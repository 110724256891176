import { css } from '@emotion/react';
import { Button as FancyButton } from '@minted/fancyclothes';
import {
  Button, TextInput, theme, Modal, ModalHeader, ModalBody, Divider, TextLinkInline, useMediaQueryState,
} from '@minted/minted-components';
import React, {
  useCallback, useState, useMemo,
} from 'react';

import API from '../../../core/api';
import { fetchGuestToken, getGuestTokenCookie } from '../../../website/actions';

const rsvpFormStyles = {
  button: css`
    width: 25%;
    ${theme.media.lessThan('medium')`
      width: auto;
    `};
  `,
  familyMobile: css`
    max-width: 75%;
  `,
  familyName: css`
    ${theme.typeStyles.bodySmallLineHeight};
    ${theme.typeStyles.bodyBoldStyles};
  `,
  nameInput: css`
    margin-bottom: ${theme.spacing.x4};
    text-align: left;
  `,
  rsvpFamily: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x6};
  `,
  searchBar: css`
    width: 75%;
  `,
  searchBarMobile: css`
    width: 90%;
  `,
  searchContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  searchHeader: css`
    ${theme.typeStyles.bodyMediumStyles};
    ${theme.typeStyles.bodyBoldStyles};
    text-align: center;
    width: 100%;
    margin-bottom: ${theme.spacing.x4}
  `,
  textLink: css`
    display: flex;
    margin-top: ${theme.spacing.x4};
    color: ${theme.colors.textSecondary};
  `,
  textLinkCta: css`
    margin-right: ${theme.spacing.base};
  `,
  wrapper: css`
    color: ${theme.colors.textPrimary};
    width: 100%;
  `,
};

const RSVPSearchForm = ({
  isReadOnly, setRsvpData,
}) => {
  const [
    searchTerm,
    setSearchTerm,
  ] = useState('');
  const [
    searchResults,
    setSearchResults,
  ] = useState([]);
  const [
    showAdditionsRSVP,
    setShowAdditionsRSVP,
  ] = useState(false);
  const [
    showResults,
    setShowResults,
  ] = useState(true);
  const [
    searchTermError,
    setSearchTermError,
  ] = useState(null);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  const resetState = useCallback(() => {
    setSearchTerm('');
    setSearchResults([]);
    setShowAdditionsRSVP(false);
    setShowResults(true);
  }, []);

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);
  const closeModal = useCallback(() => {
    resetState();
    setIsModalOpen(false);
  }, [
    resetState,
  ]);

  const getInvitesForContact = useCallback((addressbookContactId) => {
    // Contact ID from search form is passed in here, and invites are queried
    // Araceli Acevedo is 'f02b1e4a-b21a-4a40-8e4c-ea588d23b9af' for local dev
    const guestToken = getGuestTokenCookie();
    const headers = {
      Authorization: `Guest ${guestToken}`,
    };

    API.get('guest-management/rsvp-form-data', {
      headers,
    }, {
      addressbook_contact_id: addressbookContactId,
    }).then((responseData) => {
      setIsModalOpen(false);
      setRsvpData(responseData);
    });
  }, [
    setRsvpData,
  ]);

  const search = useCallback((event) => {
    event?.preventDefault();
    if (searchTerm.length === 0) {
      setSearchTermError('You must enter a name');
    } else {
      setSearchTermError(null);
      const hasToken = !!getGuestTokenCookie();
      const tokenPromises = [];

      if (!hasToken) {
        tokenPromises.push(fetchGuestToken());
      }

      // If the promise exists, we need to wait for it, so .all will have either 0 or 1 promises.
      Promise.all(tokenPromises).then(() => {
        const guestToken = getGuestTokenCookie();
        const headers = {
          Authorization: `Guest ${guestToken}`,
        };

        API.get('guest-management/search', {
          headers,
        }, {
          q: searchTerm,
        }).then((response) => {
          setSearchResults(response);
          setShowResults(true);
          setShowAdditionsRSVP(false);
          setSearchTerm('');
          if (!isModalOpen) {
            setIsModalOpen(true);
          }
        }).catch(() => {
          setSearchTermError("Sorry, we're not finding your invite. Try searching again or reach out to your host.");
        });
      });
    }
  }, [
    isModalOpen,
    searchTerm,
  ]);

  const firstSearchResult = useMemo(() => {
    if (searchResults.length > 0) {
      return searchResults[0];
    }

    return null;
  }, [
    searchResults,
  ]);

  const additionalSearchResults = useMemo(() => {
    if (searchResults.length > 1) {
      return searchResults.slice(1);
    }

    return [];
  }, [
    searchResults,
  ]);

  const getFormattedGuestNames = (guestNames) => {
    if (guestNames.every((name) => name === null)){
      return `Party of ${guestNames.length}`;
    }
    guestNames = guestNames.sort((prev, curr) => (curr ? curr.length : 0) - (prev ? prev.length : 0));

    const unnamedGuestsCount = guestNames.filter((guest) => guest === null).length;

    const areThereUnknownGuests = unnamedGuestsCount > 0;

    const formattedNames = guestNames.reduce((names, guest, index)=> {
      let separator = ', ';

      if (index === 0) {
        separator = '';
      }
      if (guestNames.length > 1 && index === guestNames.length - 1 && !areThereUnknownGuests){
        separator = ' & ';
      }
      if (guest === null){
        return names;
      }

      return `${names}${separator} ${guest}`;
    }, '');

    if (areThereUnknownGuests) {
      return `${formattedNames} & ${unnamedGuestsCount === 1 ? 'Guest' : `${unnamedGuestsCount} Guests`}`;
    }

    return formattedNames;
  };

  return (
    <div css={rsvpFormStyles.wrapper}>
      <Modal
        mobileFullscreen
        onCloseClick={closeModal}
        open={isModalOpen}
        size="medium"
      >
        <ModalHeader>
          Find your RSVP
          {showResults && ' in the list below'}
        </ModalHeader>
        <ModalBody>
          {
            showResults && (
              <>
                <div>
                  {
                    firstSearchResult && (
                      <div css={rsvpFormStyles.rsvpFamily}>
                        <div>
                          <div css={rsvpFormStyles.familyName}>
                            {firstSearchResult.partyName}
                          </div>
                          <div>
                            {getFormattedGuestNames(firstSearchResult.guestNames)}
                          </div>
                        </div>
                        <div css={rsvpFormStyles.button}>
                          <Button
                            expand
                            onClick={()=> getInvitesForContact(firstSearchResult.addressbookContactId)}
                            size={Button.sizes.medium}
                            text="Select"
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
                {
                  (showAdditionsRSVP && searchResults.length > 0) && (
                    <>
                      <Divider
                        title="OR"
                        type="light"
                      />
                      {
                        additionalSearchResults.map((additionalResult)=> (
                          <div
                            css={rsvpFormStyles.rsvpFamily}
                            key={additionalResult.addressbookContactId}
                          >
                            <div css={isMobile && rsvpFormStyles.familyMobile}>
                              <div css={rsvpFormStyles.familyName}>
                                {additionalResult.partyName}
                              </div>
                              <div>
                                {getFormattedGuestNames(additionalResult.guestNames)}
                              </div>
                            </div>
                            <div css={rsvpFormStyles.button}>
                              <Button
                                expand
                                onClick={()=> getInvitesForContact(additionalResult.addressbookContactId)}
                                size={Button.sizes.medium}
                                text="Select"
                                type={Button.types.secondary}
                              />
                            </div>
                          </div>
                        ))
                      }
                      {
                        searchResults.length > 0
                          && (
                            <div css={rsvpFormStyles.textLink}>
                              <div css={rsvpFormStyles.textLinkCta}>
                                (Still nothing?
                              </div>
                              <TextLinkInline
                                href="#"
                                onClick={() =>  setShowResults(false)}
                                text="Try another search"
                                type="secondaryAlternate"
                              />
                              )
                            </div>
                          )
                      }

                    </>
                  )
                }
                {
                  (!showAdditionsRSVP && searchResults.length > 1) && (
                    <>
                      <Divider
                        title=""
                        type="light"
                      />
                      <div css={rsvpFormStyles.textLink}>
                        <div css={rsvpFormStyles.textLinkCta}>
                          Not You?
                        </div>
                        <TextLinkInline
                          href="#"
                          onClick={() =>  setShowAdditionsRSVP(true)}
                          text="Pick a different name."
                          type="secondaryAlternate"
                        />
                      </div>
                    </>
                  )
                }
              </>
            )
          }
          {
            (searchResults.length <= 1 && showResults) && (
              <>
                <Divider
                  title=""
                  type="light"
                />
                <div css={rsvpFormStyles.textLink}>
                  <span>
                    Not finding your invite?
                    {' '}
                    <TextLinkInline
                      href="#"
                      onClick={
                        () => {
                          setShowResults(false);
                        }
                      }
                      text="Try searching again"
                      type="secondaryAlternate"
                    />
                    {' '}
                    or reach out to your host
                  </span>
                </div>
              </>
            )
          }
          {
            !showResults && (
              <div css={rsvpFormStyles.searchContent}>
                <div css={isMobile ? rsvpFormStyles.searchBarMobile : rsvpFormStyles.searchBar}>
                  <div css={rsvpFormStyles.searchHeader}>
                    Enter your name to RSVP
                  </div>
                  <div css={rsvpFormStyles.nameInput}>
                    <TextInput
                      error={searchTermError}
                      label="Enter your name"
                      onChange={
                        (event) => {
                          setSearchTerm(event.target.value); setSearchTermError(null);
                        }
                      }
                      onKeyDown={
                        (event) => ((event.key === 'Enter') && search())
                      }
                      size="medium"
                      touched
                      value={searchTerm}
                    />
                  </div>
                  <Button
                    disabled={isReadOnly}
                    expand
                    onClick={search}
                    text="Find RSVP"
                    type="primary"
                  />
                </div>
              </div>
            )
          }
        </ModalBody>
      </Modal>

      <div css={rsvpFormStyles.nameInput}>
        <TextInput
          disabled={isReadOnly}
          error={searchTermError}
          onChange={
            (event) => {
              setSearchTerm(event.target.value); setSearchTermError(null);
            }
          }
          onKeyDown={
            (event) => ((event.key === 'Enter') && search(event))
          }
          size="small"
          touched
          value={searchTerm}
        />
      </div>
      <FancyButton
        block={true}
        disabled={isReadOnly}
        onClick={search}
        text="Find RSVP"
      />
    </div>
  );
};

export default RSVPSearchForm;
