import { css } from '@emotion/react';
import { styleUtils } from '@minted/minted-components';
import { string } from 'prop-types';
import React from 'react';

import { getEventDateTimeComponent } from '../utils';

const marginBottomStyles = css`
  margin-bottom: ${styleUtils.rem(20)};
`;

export const EventDetail = ({
  address,
  date,
  endDate,
  endTime,
  time,
  venue,
}) => (
  <div css={marginBottomStyles}>
    {
      getEventDateTimeComponent(date, time, endDate, endTime)
    }

    {
      venue
        && (
          <p>
            {venue}
          </p>
        )
    }

    {
      address
        && (
          <p>
            {address}
          </p>
        )
    }
  </div>
);

EventDetail.propTypes = {
  address: string.isRequired,
  date: string.isRequired,
  time: string.isRequired,
  venue: string.isRequired,
};

export default EventDetail;
export { EventDetail as BareEventDetail };
