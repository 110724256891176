import { css } from '@emotion/react';
import {
  Button,
  theme,
  styleUtils,
  useMediaQueryState,
} from '@minted/minted-components';
import isEmpty from 'lodash/isEmpty';
import React, {
  useCallback, useMemo, useState, useEffect, useRef,
} from 'react';

import API from '../../../core/api';
import {
  ATTENDING, AWAITING_REPLY, NOT_ATTENDING,
} from '../../../guests/constants';
import EventSummaryEmail from '../../../rsvp/website/EventSummaryEmail';
import { getGuestTokenCookie } from '../../../website/actions';
import { isGuestNamed } from '../../dashboard/utils';
import { QUESTION_WITH_ERROR_CLASSNAME } from '../../rsvp/constants';
import EventDetails from '../../rsvp/EventDetails';
import EventGuest from '../../rsvp/EventGuest';
import EventsGuestsSummary from '../../rsvp/EventsGuestsSummary';
import { scrollToElement } from '../utils';

const rsvpStyles = {
  buttonsWrapper: css`
    margin-bottom: ${theme.spacing.x5};
    display: flex;
    gap: ${theme.spacing.x4};
  `,
  buttonWrapper: css`
    display: flex;
    margin-top: ${theme.spacing.x4};
    margin-bottom: ${theme.spacing.x4};
  `,
  emailMargins: css`
    margin-left: ${theme.spacing.x5};
    margin-right: ${theme.spacing.x5};
    margin-bottom: ${theme.spacing.x5};
  `,
  formWrapper: css`
    background-color: ${theme.colors.gray200};
    display: grid;
    gap: ${theme.spacing.x5};
    padding: ${theme.spacing.x5};
    ${theme.media.greaterThan('small')`
      padding: ${theme.spacing.x4};
    `};
  `,
  wrapper: css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.textPrimary};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    margin-top: ${theme.spacing.x5};
    margin-bottom: ${styleUtils.rem(100)};
    margin-left: ${styleUtils.rem(100)};
    margin-right: ${styleUtils.rem(100)};
    padding-left: ${theme.spacing.x4};
    padding-right: ${theme.spacing.x4};
    text-align: initial;
    ${theme.media.lessThan('medium')`
      margin-left: 0;
      margin-right: 0;
    `};
  `,
};

const getInitialFormState = (contact, guests, events) => {
  const updatedGuestNamesByGuestId = {};
  const eventResponsesByEventId = {};
  const guestIds = [];

  for (const guest of guests) {
    guestIds.push(guest.id);
    if (!isGuestNamed(guest)) {
      // Fill out unnamed guest object
      if (guests.length > 1) {
        updatedGuestNamesByGuestId[guest.id] = {
          firstName: '',
          lastName: '',
        };
      } else {
        // Only one guest, parse contact name into guest update structure
        const lastSpaceIndex = contact.name.lastIndexOf(' ');
        let firstName = '';
        let lastName = '';

        if (lastSpaceIndex > -1) {
          firstName = contact.name.substring(0, lastSpaceIndex);
          lastName = contact.name.substring(lastSpaceIndex + 1);
        } else {
          lastName = contact.name;
        }

        updatedGuestNamesByGuestId[guest.id] = {
          firstName,
          lastName,
        };
      }
    }
  }

  for (const event of events) {
    const guestData = {};

    for (const guestId of guestIds) {
      if (event.guests.includes(guestId)) {
        guestData[guestId] = {
          answers: {},
          responseType: AWAITING_REPLY,
        };
      }
    }
    eventResponsesByEventId[event.id] = guestData;
  }

  return {
    contactId: contact.id,
    emails: [
      {
        isValid: false,
        value: '',
      },
    ],
    eventResponsesByEventId,
    updatedGuestNamesByGuestId,
  };
};

const getGuestIdsToValidate = (guestsResponses = {}) => {
  const forms = Object.entries(guestsResponses);

  const formsToValidate = [];
  const responsesTypeForValidation = [
    ATTENDING,
    AWAITING_REPLY,
  ];

  for (const [
    guestId,
    responses,
  ] of forms) {
    if (responsesTypeForValidation.includes(responses.responseType)) {
      formsToValidate.push(parseInt(guestId));
    }
  }

  return formsToValidate;
};

const DESKTOP_NAVBAR_OFFSET = 54;
const MOBILE_NAVBAR_OFFSET = 44;

const RSVPForm = ({
  contact,
  events,
  guests,
  siteType,
}) => {
  const [
    formData,
    setFormData,
  ] = useState(getInitialFormState(contact, guests, events));
  const [
    showSummary,
    setShowSummary,
  ] = useState(false);
  const [
    isSubmitting,
    setIsSubmitting,
  ] = useState(false);
  const [
    currentEventIndex,
    setCurrentEventIndex,
  ] = useState(0);

  const [
    hasNextBeenClicked,
    setHasNextBeenClicked,
  ] = useState(false);

  const [
    guestsFormsToValidate,
    setGuestsFormsToValidate,
  ] = useState([]);

  const wrapper = useRef(null);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';
  const totalEvents = events.length;
  const showSubmitButton = currentEventIndex === totalEvents - 1;
  const showNextButton = totalEvents > 1 && currentEventIndex < totalEvents - 1;
  const showBackButton = totalEvents > 1 && currentEventIndex > 0;
  const currentEvent = events[currentEventIndex];
  // used to automatic scroll on errors or next/submit click
  const navbarOffset = isMobile ? MOBILE_NAVBAR_OFFSET : DESKTOP_NAVBAR_OFFSET;

  const canEditName = useCallback((guestId) => {
    /* Guest names can only be edited under specific circumstances. The guest must be
    * unnamed, and the current event must be the first event that the guest has not
    * yet responded "no" to. So if this is the first time the guest may attend an event,
    * we display the name prompt.
    * */
    const guest = guests.find((guest) => `${guest.id}` === `${guestId}`);

    // Can only edit unnamed guests
    if (isGuestNamed(guest)) {
      return false;
    }

    // Find first event that the guest may be attending, check if it's this one
    for (const event of events) {
      const eventResponses = formData.eventResponsesByEventId[event.id];

      // Check events in order to see if the guest is attending any before the current event
      if (event.guests.includes(guestId) && eventResponses) {
        const eventResponse = eventResponses[guest.id];

        // Return false if not attending the current event, or if attending a previous event
        if (event.id === currentEvent.id) {
          if (eventResponse?.responseType === NOT_ATTENDING) {
            return false;
          }
        } else {
          if (eventResponse?.responseType === ATTENDING) {
            return false;
          }
        }
      }
      // Stop checking once we get to the current event
      if (event.id === currentEvent.id) {
        break;
      }
    }

    return true;
  }, [
    currentEvent,
    events,
    formData.eventResponsesByEventId,
    guests,
  ]);

  const isFormComplete = useMemo(() => {
    const requiredQuestions = currentEvent.questions.filter((question) => question.required);
    const guestResponses = formData.eventResponsesByEventId[currentEvent.id];

    for (const [
      guestId,
      response,
    ] of Object.entries(guestResponses)) {
      // Every guest must reply
      if (response.responseType === AWAITING_REPLY) {
        return false;
      } else if (response.responseType === ATTENDING) {
        // Editable names must be filled in
        if (canEditName(guestId)) {
          const newGuestName = formData.updatedGuestNamesByGuestId[guestId];

          if (!isGuestNamed(newGuestName)) {
            return false;
          }
        }
        // All required questions must be answered
        for (const question of requiredQuestions) {
          if (!response.answers[question.id]) {
            return false;
          }
        }
      }
    }

    return true;
  }, [
    canEditName,
    currentEvent,
    formData,
  ]);

  const updateGuestResponses = useCallback((guestId, newResponses, shouldResetFormValidation) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      eventResponsesByEventId: {
        ...prevFormData.eventResponsesByEventId,
        [currentEvent.id]: {
          ...prevFormData.eventResponsesByEventId[currentEvent.id],
          // Recreate object, replacing guest responses for current event
          [guestId]: newResponses,
        },
      },
    }));

    if (shouldResetFormValidation) {
      setGuestsFormsToValidate((prevForms) => (prevForms.filter((form) => (guestId !== form))));
    }
  }, [
    currentEvent,
  ]);

  const updateGuestName = useCallback((guestId, namePart, value) => {
    setFormData((prevState) => {
      // Get existing guest name value
      const guestName = prevState.updatedGuestNamesByGuestId[guestId] || {};

      // Update based on name type
      if (namePart === 'firstName') {
        guestName.firstName = value;
      } else {
        guestName.lastName = value;
      }

      // Update form state with new value
      return {
        ...prevState,
        updatedGuestNamesByGuestId: {
          ...prevState.updatedGuestNamesByGuestId,
          [guestId]: {
            ...guestName,
          },
        },
      };
    });
  }, []);

  const updateEmails = useCallback((newEmails) => {
    setFormData((prevState) => ({
      ...prevState,
      emails: newEmails,
    }));
  }, []);

  const updateGuestIdsToValidate = useCallback(() => {
    const guestsFormsToValidate = getGuestIdsToValidate(formData.eventResponsesByEventId[currentEvent.id]);

    setGuestsFormsToValidate(guestsFormsToValidate);
    setHasNextBeenClicked(true);

    setTimeout(() => {
      const wrappersWithErrors = document.querySelectorAll(`.${QUESTION_WITH_ERROR_CLASSNAME}`);

      if (!isEmpty(wrappersWithErrors)) {
        scrollToElement(wrappersWithErrors[0], navbarOffset);
      }
    }, 1);
  }, [
    currentEvent,
    formData.eventResponsesByEventId,
    navbarOffset,
  ]);

  const onNextClick = useCallback(() => {
    if (isFormComplete) {
      setCurrentEventIndex((prev) => (prev + 1));
    } else {
      updateGuestIdsToValidate();
    }
  }, [
    isFormComplete,
    updateGuestIdsToValidate,
  ]);

  useEffect(() => {
    setHasNextBeenClicked(false);
    setGuestsFormsToValidate([]);
  }, [
    currentEventIndex,
  ]);

  const onBackClick = useCallback(() => {
    setCurrentEventIndex((prev) => (prev - 1));
  }, []);

  const submitRsvp = useCallback(() => {
    if (!isFormComplete) {
      updateGuestIdsToValidate();

      return null;
    }

    // Flatten event responses object to list
    const eventResponses = [];

    for (const [
      eventId,
      guestResponsesForEvent,
    ] of Object.entries(formData.eventResponsesByEventId)) {
      // Flatten guest responses object to list
      const guestResponses = [];

      for (const [
        guestId,
        guestResponse,
      ] of Object.entries(guestResponsesForEvent)) {
        // Flatten answers object to list
        const answers = [];

        for (const [
          questionId,
          answer,
        ] of Object.entries(guestResponse.answers)) {
          answers.push({
            answer,
            questionId,
          });
        }
        guestResponses.push({
          answers,
          guestId,
          responseType: guestResponse.responseType,
        });
      }
      eventResponses.push({
        eventId,
        eventResponseData: guestResponses,
      });
    }

    // Flatten guest names as well
    const updatedGuestNames = [];

    for (const [
      guestId,
      newGuestName,
    ] of Object.entries(formData.updatedGuestNamesByGuestId)) {
      updatedGuestNames.push({
        guestId,
        ...newGuestName,
      });
    }

    const guestToken = getGuestTokenCookie();
    const headers = {
      Authorization: `Guest ${guestToken}`,
    };

    const requestData = {
      addressbookContactId: formData.contactId,
      emails: formData.emails.map((email) => email.value).filter((email) => !!email),
      eventResponses,
      updatedGuestNames,
    };

    setIsSubmitting(true);
    API.post('guest-management/rsvp-household', {
      headers,
    }, requestData).then((responseData) => {
      setShowSummary(true);

      scrollToElement(wrapper.current, navbarOffset);
    }).finally(() => setIsSubmitting(false));
  }, [
    formData,
    isFormComplete,
    navbarOffset,
    updateGuestIdsToValidate,
  ]);

  const onChangeResponse = () => {
    setCurrentEventIndex(0);
    setShowSummary(false);
  };

  useEffect(() => {
    scrollToElement(wrapper.current, navbarOffset);
  }, [
    currentEventIndex,
    navbarOffset,
  ]);

  return (
    <div
      css={rsvpStyles.wrapper}
      data-cy="rsvpForm"
      ref={wrapper}
    >
      {
        showSummary ?
          (
            <div>
              <EventsGuestsSummary
                contact={contact}
                events={events}
                guests={guests}
                guestsResponses={formData.eventResponsesByEventId}
                isMobile={isMobile}
                siteType={siteType}
                updatedGuestNames={formData.updatedGuestNamesByGuestId}
              />
              <div css={rsvpStyles.buttonWrapper}>
                <Button
                  expand
                  onClick={onChangeResponse}
                  size="large"
                  text="Change Response"
                  type="primary"
                />
              </div>
            </div>
          ) :
          (
            <>
              <EventDetails
                event={currentEvent}
                index={currentEventIndex}
                isMobile={isMobile}
                totalEvents={totalEvents}
              />

              <div css={rsvpStyles.formWrapper}>
                {
                  guests.filter((guest) => currentEvent.guests.includes(guest.id)).map((guest) => (
                    <EventGuest
                      canEditName={canEditName}
                      guest={guest}
                      guestResponse={formData.eventResponsesByEventId[currentEvent.id][guest.id]}
                      key={`${guest.id}`}
                      questions={currentEvent.questions}
                      updatedGuestName={formData.updatedGuestNamesByGuestId[guest.id]}
                      updateGuestName={updateGuestName}
                      updateGuestResponses={updateGuestResponses}
                      validateErrors={hasNextBeenClicked && guestsFormsToValidate.includes(guest.id)}
                    />
                  ))
                }
              </div>

              {
                showSubmitButton &&  (
                  <div css={rsvpStyles.emailMargins}>
                    <EventSummaryEmail
                      emails={formData.emails}
                      eventCount={totalEvents}
                      isSubmitting={isSubmitting}
                      updateEmails={updateEmails}
                    />
                  </div>
                )
              }

              <div css={rsvpStyles.buttonsWrapper}>
                {
                  showBackButton && (
                    <Button
                      disabled={isSubmitting}
                      expand
                      onClick={onBackClick}
                      size="large"
                      text="Back"
                      type="secondary"
                    />
                  )
                }

                {
                  showNextButton && (
                    <Button
                      expand
                      onClick={onNextClick}
                      size="large"
                      text="Next"
                      type="primary"
                    />
                  )
                }

                {
                  showSubmitButton && (
                    <Button
                      disabled={isSubmitting}
                      expand
                      onClick={submitRsvp}
                      size="large"
                      text="Submit"
                      type="primary"
                    />
                  )
                }
              </div>
            </>
          )
      }
    </div>
  );
};

export default RSVPForm;
