import isEmpty from 'lodash/isEmpty';

import { NOT_ATTENDING } from '../../guests/constants';

const getEventRequiredQuestionsIds = (events) => {
  const eventsWithRequiredQuestions = events.filter(({
    questions,
  }) => (questions.some(({
    required,
  }) => (required))));

  const eventsRequiredQuestions = Object.fromEntries(eventsWithRequiredQuestions.map(({
    id, questions,
  }) => ([
    id,
    questions.filter((question) => (question.required)).map((question) => (question.id)),
  ])));

  return eventsRequiredQuestions;
};

export const getGuestWithMissingResponses = (guests, events) => {
  const eventsRequiredQuestions = getEventRequiredQuestionsIds(events);

  const guestsWithMissingResponses = guests.map((guest) => {
    const {
      invitedToEventIds,
      nameUnknown,
      responses,
    } = guest;

    if (nameUnknown) { // no necessity to validate answers, form is hidden
      return {
        ...guest,
        hasMissingResponses: false,
      };
    }

    const invitedToEventsWithRequiredQuestionsIds = invitedToEventIds.filter((eventId) => (eventsRequiredQuestions[eventId]));
    const isInvitedToEventsWithRequiredQuestions = invitedToEventsWithRequiredQuestionsIds.length > 0;

    if (!isInvitedToEventsWithRequiredQuestions) { // no necessity to validate answers
      return {
        ...guest,
        hasMissingResponses: false,
      };
    }

    const hasAnyEventWithMissingResponses = invitedToEventsWithRequiredQuestionsIds.some((eventId) => {
      const eventRequiredQuestionsId = eventsRequiredQuestions[eventId];
      const isNotAttending = responses.find((res) => (res.eventId === eventId))?.responseType === NOT_ATTENDING;

      if (isNotAttending) {
        return false;
      }

      const eventQuestionsAnswers = responses.find((res) => (res.eventId === eventId))?.answers ?? [];

      if (isEmpty(eventQuestionsAnswers)) { // hasn't responded any required question yet
        return true;
      }

      const hasAnyMissingResponse = eventRequiredQuestionsId.some((requiredQuestionId) => {
        const questionAnswer = eventQuestionsAnswers.find(({
          questionId,
        }) => (questionId === requiredQuestionId));

        const missingResponse = isEmpty(questionAnswer?.answer);

        return missingResponse;
      });

      return hasAnyMissingResponse;
    });

    return {
      ...guest,
      hasMissingResponses: hasAnyEventWithMissingResponses,
    };
  });

  return guestsWithMissingResponses;
};

export const getGuestWithMissingResponsesIndexes = (guests, events) => {
  const guestsWithMissingResponses = getGuestWithMissingResponses(guests, events);
  const guestWithMissingResponsesIds = [];

  for (const [
    index,
    guest,
  ] of Object.entries(guestsWithMissingResponses)) {
    if (guest.hasMissingResponses) {
      guestWithMissingResponsesIds.push(parseInt(index));
    }
  }

  return guestWithMissingResponsesIds;
};

export const scrollToElement = (element, offset = 0, scrollIntoView = false) => {
  const top = element.getBoundingClientRect().top;
  const scrollY = window.scrollY;
  const isVisible = top - offset > 0;

  if (!isVisible) {
    if (scrollIntoView) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: top + scrollY - offset,
      });
    }
  }
};
