import React from 'react';
import { connect } from 'react-redux';

import HolidayWebsiteContent from './HolidayWebsiteContent';
import { selectSite } from '../../selectors';
import FancyClothesProvider from '../FancyClothesProvider';

export const HolidayWebsite = ({
  site,
}) => (
  <FancyClothesProvider>
    <HolidayWebsiteContent
      site={site}
    />
  </FancyClothesProvider>
);

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    site,
  };
};

export default connect(mapStateToProps)(HolidayWebsite);
